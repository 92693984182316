import React, { useEffect, useState } from 'react';
import { Box, Typography, Container, Button, Paper, Divider, Grid } from '@mui/material';
import { Helmet } from 'react-helmet';
import { useParams, useNavigate } from 'react-router-dom';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import InsertDriveFileOutlinedIcon from '@mui/icons-material/InsertDriveFileOutlined';
import ShieldOutlinedIcon from '@mui/icons-material/ShieldOutlined';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

function AdminWarrantyDetail() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [warranty, setWarranty] = useState(null);
  const [error, setError] = useState('');
  const API_URL = process.env.REACT_APP_API_URL;

  useEffect(() => {
    const fetchWarranty = async () => {
      try {
        const token = localStorage.getItem('adminToken') || localStorage.getItem('token');
        const response = await fetch(`${API_URL}/warranty/${id}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        if (!response.ok) {
          throw new Error("Erreur lors de la récupération des détails de l'outil");
        }
        const data = await response.json();
        setWarranty(data);
      } catch (err) {
        setError(err.message);
      }
    };

    fetchWarranty();
  }, [id, API_URL]);

  const handleDownloadCertificate = () => {
    window.open(`${API_URL}/warranty/certificate/${id}`, '_blank');
  };

  const handleDownloadInvoice = () => {
    window.open(`${API_URL}/warranty/invoice/${id}`, '_blank');
  };

  const handleDownloadLabel = () => {
    window.open(`${API_URL}/warranty/label/${id}`, '_blank');
  };

  if (error) {
    return (
      <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
        <Typography variant="h6" color="error">
          {error}
        </Typography>
      </Container>
    );
  }

  if (!warranty) {
    return (
      <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
        <Typography variant="h6">Chargement des données...</Typography>
      </Container>
    );
  }

  return (
    <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
      <Helmet>
        <title>Détails de l'Outil - myHanger</title>
        <meta name="description" content="Consultez les détails de l'outil enregistré avec votre garantie." />
      </Helmet>

      <Box sx={{ textAlign: 'left', mb: 2 }}>
        <Button variant="contained" color="primary" onClick={() => navigate(-1)}>
          Retour à la liste des garanties
        </Button>
      </Box>

      <Box sx={{ textAlign: 'center', mb: 4 }}>
        <img
          src={warranty.productId?.imageUrl || '/default-product-image.png'}
          alt={warranty.productId?.model || 'Produit'}
          style={{ maxWidth: '300px', height: 'auto' }}
        />
        <Typography variant="h4" component="h1" gutterBottom>
          {warranty.productId?.model || 'Produit'}
        </Typography>
        <Typography variant="subtitle1" gutterBottom>
          {warranty.productId?.category}
        </Typography>
      </Box>

      <Grid container spacing={4} sx={{ mb: 4 }}>
        <Grid item xs={12} md={6}>
          <Paper elevation={3} sx={{ p: 3, backgroundColor: '#f5f5f5' }}>
            <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
              <InfoOutlinedIcon color="primary" sx={{ mr: 1, fontSize: 24 }} />
              <Typography variant="h6">Données Outil</Typography>
            </Box>
            <Divider sx={{ mb: 2 }} />
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
              <Typography variant="body1">
                <strong>Numéro de garantie : </strong>{warranty.warrantyNumber || 'N/A'}
              </Typography>
              <Typography variant="body1">
                <strong>Numéro de produit : </strong>{warranty.productId?.productNumber || 'N/A'}
              </Typography>
              <Typography variant="body1">
                <strong>Numéro de série : </strong>{warranty.serialNumberId?.serialNumber || 'N/A'}
              </Typography>
              <Typography variant="body1">
                <strong>Date d'achat : </strong>{warranty.purchaseDate ? new Date(warranty.purchaseDate).toLocaleDateString('fr-FR') : 'Date invalide'}
              </Typography>
            </Box>
          </Paper>
        </Grid>

        <Grid item xs={12} md={6}>
          <Paper elevation={3} sx={{ p: 3, backgroundColor: '#f5f5f5' }}>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
              <ShieldOutlinedIcon color="primary" sx={{ fontSize: 30 }} />
              <Typography variant="h6" gutterBottom>
                Service
              </Typography>
            </Box>
            <Divider sx={{ mb: 2 }} />
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
              <Box>
                <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
                  {warranty.productId?.warrantyPeriod
                    ? `${Math.ceil(warranty.productId.warrantyPeriod / 12)} Ans de Garantie`
                    : 'Durée de garantie inconnue'}
                </Typography>
                <Typography variant="body2">
                  Valable jusqu'au {warranty.warrantyEndDate ? new Date(warranty.warrantyEndDate).toLocaleDateString('fr-FR') : 'Date invalide'}
                </Typography>
              </Box>
            </Box>
            <Box sx={{ textAlign: 'right', mt: 2 }}>
              <Button
                variant="contained"
                color="primary"
                onClick={handleDownloadCertificate}
              >
                Télécharger le certificat
              </Button>
            </Box>
          </Paper>
        </Grid>
      </Grid>

      <Paper elevation={3} sx={{ p: 3, mt: 4, backgroundColor: '#f9f9f9' }}>
        <Typography variant="h6" gutterBottom sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          <DescriptionOutlinedIcon color="primary" />
          Annexes
        </Typography>
        <Divider sx={{ mb: 2 }} />

        <Box sx={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center', textAlign: 'center' }}>
          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <DescriptionOutlinedIcon color="primary" sx={{ fontSize: 40, mb: 1 }} />
            <Typography variant="body1" sx={{ fontWeight: 'bold', color: '#333' }}>Facture</Typography>
            <Button
              variant="contained"
              color="primary"
              startIcon={<InsertDriveFileOutlinedIcon />}
              onClick={handleDownloadInvoice}
              sx={{ mt: 1 }}
            >
              Télécharger
            </Button>
          </Box>

          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <DescriptionOutlinedIcon color="primary" sx={{ fontSize: 40, mb: 1 }} />
            <Typography variant="body1" sx={{ fontWeight: 'bold', color: '#333' }}>Étiquette signalétique</Typography>
            <Button
              variant="contained"
              color="primary"
              startIcon={<InsertDriveFileOutlinedIcon />}
              onClick={handleDownloadLabel}
              sx={{ mt: 1 }}
            >
              Télécharger
            </Button>
          </Box>
        </Box>
      </Paper>
    </Container>
  );
}

export default AdminWarrantyDetail;
