import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { StandaloneSearchBox, useJsApiLoader } from '@react-google-maps/api';
import {
  Container,
  Grid,
  Card,
  CardContent,
  TextField,
  Typography,
  Button,
  Snackbar,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  FormControlLabel,
  Checkbox,
  Radio,
  RadioGroup,
  Box,
  useTheme,
  useMediaQuery,
  Alert,
} from '@mui/material';
import { GlobalStyles } from '@mui/system';

const libraries = ['places'];

function Profile() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const navigate = useNavigate();

  // États
  const [user, setUser] = useState({
    firstName: '',
    lastName: '',
    email: '',
    optIn: false,
    address: null,
  });
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: '' });
  const [addressDialogOpen, setAddressDialogOpen] = useState(false);
  const [emailDialogOpen, setEmailDialogOpen] = useState(false);
  const [passwordDialogOpen, setPasswordDialogOpen] = useState(false);
  const [selectedAddress, setSelectedAddress] = useState(null);
  const [addressType, setAddressType] = useState('particulier');
  const [companyName, setCompanyName] = useState('');
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [newEmail, setNewEmail] = useState('');
  const [searchBox, setSearchBox] = useState(null);

  const inputRef = useRef();

  const { isLoaded, loadError } = useJsApiLoader({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
    libraries,
  });

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/users/profile`, {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        });
        if (!response.ok) throw new Error('Erreur lors de la récupération des données utilisateur');
        const userData = await response.json();
        setUser(userData);
      } catch (error) {
        setSnackbar({ open: true, message: error.message, severity: 'error' });
      }
    };

    fetchUserData();
  }, []);

  const handleLogout = () => {
    try {
      localStorage.removeItem('token');
      localStorage.clear();
      navigate('/login');
    } catch (error) {
      setSnackbar({
        open: true,
        message: 'Erreur lors de la déconnexion',
        severity: 'error',
      });
    }
  };

  const handleCloseSnackbar = () => setSnackbar({ ...snackbar, open: false });

  const onLoad = (ref) => setSearchBox(ref);

  const onPlacesChanged = () => {
    if (searchBox) {
      const places = searchBox.getPlaces();
      if (places && places.length > 0) {
        const place = places[0];
        if (!place.address_components) {
          setSnackbar({
            open: true,
            message: 'Impossible de récupérer les composants de cette adresse',
            severity: 'error',
          });
          return;
        }

        let address = '';
        let postalCode = '';
        let city = '';
        let country = '';

        place.address_components.forEach((component) => {
          const componentType = component.types[0];
          switch (componentType) {
            case 'street_number':
              address = `${component.long_name} ${address}`;
              break;
            case 'route':
              address += component.long_name;
              break;
            case 'locality':
              city = component.long_name;
              break;
            case 'postal_code':
              postalCode = component.long_name;
              break;
            case 'country':
              country = component.long_name;
              break;
            default:
              break;
          }
        });

        setSelectedAddress({
          addressLine: address.trim(),
          postalCode,
          city,
          country,
        });
      }
    }
  };

  const handleUpdateProfile = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/users/profile`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
        body: JSON.stringify({
          firstName: user.firstName,
          lastName: user.lastName,
        }),
      });
      if (!response.ok) throw new Error('Erreur lors de la mise à jour du profil');
      setSnackbar({ open: true, message: 'Profil mis à jour avec succès', severity: 'success' });
    } catch (error) {
      setSnackbar({ open: true, message: error.message, severity: 'error' });
    }
  };

  const handleUpdateEmail = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/users/update-email`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
        body: JSON.stringify({ newEmail, password: currentPassword }),
      });
      if (!response.ok) throw new Error("Erreur lors de la mise à jour de l'email");
      setUser({ ...user, email: newEmail });
      setEmailDialogOpen(false);
      setCurrentPassword('');
      setNewEmail('');
      setSnackbar({ open: true, message: 'Email mis à jour avec succès', severity: 'success' });
    } catch (error) {
      setSnackbar({ open: true, message: error.message, severity: 'error' });
    }
  };

  const handleUpdatePassword = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/users/update-password`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
        body: JSON.stringify({ currentPassword, newPassword }),
      });
      if (!response.ok) throw new Error('Erreur lors de la mise à jour du mot de passe');
      setPasswordDialogOpen(false);
      setCurrentPassword('');
      setNewPassword('');
      setSnackbar({ open: true, message: 'Mot de passe mis à jour avec succès', severity: 'success' });
    } catch (error) {
      setSnackbar({ open: true, message: error.message, severity: 'error' });
    }
  };

  const handleSaveAddress = async () => {
    try {
      const addressData = {
        ...selectedAddress,
        type: addressType,
        ...(addressType === 'professionnel' && { companyName }),
      };

      const response = await fetch(`${process.env.REACT_APP_API_URL}/users/update-address`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
        body: JSON.stringify(addressData),
      });

      if (!response.ok) throw new Error("Erreur lors de la mise à jour de l'adresse");

      setUser((prevUser) => ({
        ...prevUser,
        address: addressData,
      }));
      setAddressDialogOpen(false);
      setSnackbar({ open: true, message: 'Adresse mise à jour avec succès', severity: 'success' });
    } catch (error) {
      setSnackbar({ open: true, message: error.message, severity: 'error' });
    }
  };

  if (loadError) {
    return (
      <Container maxWidth="lg" sx={{ py: { xs: 2, sm: 4 } }}>
        <Typography variant="h6" color="error">
          Erreur lors du chargement de la carte Google Maps
        </Typography>
      </Container>
    );
  }

  if (!isLoaded) {
    return (
      <Container maxWidth="lg" sx={{ py: { xs: 2, sm: 4 } }}>
        <Typography variant="h6">Chargement...</Typography>
      </Container>
    );
  }

  return (
    <>
      <GlobalStyles styles={{ '.pac-container': { zIndex: 2000 } }} />
      <Container maxWidth="lg" sx={{ py: { xs: 2, sm: 4 } }}>
        <Typography
          variant={isMobile ? 'h5' : 'h4'}
          align="center"
          sx={{ mb: { xs: 3, sm: 4 }, color: theme.palette.primary.main, fontWeight: 700 }}
        >
          Mon Profil
        </Typography>

        <Grid container spacing={3}>
          {/* Informations personnelles */}
          <Grid item xs={12} md={6}>
            <Card
              sx={{
                borderRadius: 2,
                boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.05)',
              }}
            >
              <CardContent>
                <Typography variant="h6" gutterBottom color="text.primary" sx={{ fontWeight: 600 }}>
                  Informations personnelles
                </Typography>
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                  <TextField
                    fullWidth
                    label="Prénom"
                    value={user.firstName}
                    onChange={(e) => setUser({ ...user, firstName: e.target.value })}
                    sx={{
                      '& .MuiOutlinedInput-root': {
                        borderRadius: '12px',
                      },
                    }}
                  />
                  <TextField
                    fullWidth
                    label="Nom"
                    value={user.lastName}
                    onChange={(e) => setUser({ ...user, lastName: e.target.value })}
                    sx={{
                      '& .MuiOutlinedInput-root': {
                        borderRadius: '12px',
                      },
                    }}
                  />
                  <Button
                    fullWidth
                    variant="contained"
                    onClick={handleUpdateProfile}
                    sx={{
                      mt: 1,
                      py: 1.5,
                      borderRadius: '12px',
                      textTransform: 'none',
                      fontWeight: 'bold',
                    }}
                  >
                    Enregistrer les modifications
                  </Button>
                </Box>
              </CardContent>
            </Card>
          </Grid>

          {/* Email */}
          <Grid item xs={12} md={6}>
            <Card
              sx={{
                borderRadius: 2,
                boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.05)',
              }}
            >
              <CardContent>
                <Typography variant="h6" gutterBottom color="text.primary" sx={{ fontWeight: 600 }}>
                  Email
                </Typography>
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                  <TextField
                    fullWidth
                    label="Email actuel"
                    value={user.email}
                    disabled
                    sx={{
                      '& .MuiOutlinedInput-root': {
                        borderRadius: '12px',
                      },
                    }}
                  />
                  <Button
                    fullWidth
                    variant="contained"
                    onClick={() => setEmailDialogOpen(true)}
                    sx={{
                      mt: 1,
                      py: 1.5,
                      borderRadius: '12px',
                      textTransform: 'none',
                      fontWeight: 'bold',
                    }}
                  >
                    Modifier l'email
                  </Button>
                </Box>
              </CardContent>
            </Card>
          </Grid>

          {/* Adresse */}
          <Grid item xs={12}>
            <Card
              sx={{
                borderRadius: 2,
                boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.05)',
              }}
            >
              <CardContent>
                <Typography variant="h6" gutterBottom color="text.primary" sx={{ fontWeight: 600 }}>
                  Adresse
                </Typography>
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                  {user.address ? (
                    <>
                      <Typography>
                        {user.address.addressLine}, {user.address.postalCode} {user.address.city}
                      </Typography>
                      <Button
                        variant="contained"
                        onClick={() => setAddressDialogOpen(true)}
                        sx={{
                          mt: 1,
                          py: 1.5,
                          borderRadius: '12px',
                          textTransform: 'none',
                          fontWeight: 'bold',
                          maxWidth: 200,
                        }}
                      >
                        Modifier l'adresse
                      </Button>
                    </>
                  ) : (
                    <Button
                      variant="contained"
                      onClick={() => setAddressDialogOpen(true)}
                      sx={{
                        mt: 1,
                        py: 1.5,
                        borderRadius: '12px',
                        textTransform: 'none',
                        fontWeight: 'bold',
                        maxWidth: 200,
                      }}
                    >
                      Ajouter une adresse
                    </Button>
                  )}
                </Box>
              </CardContent>
            </Card>
          </Grid>

          {/* Sécurité */}
          <Grid item xs={12} md={6}>
            <Card
              sx={{
                borderRadius: 2,
                boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.05)',
              }}
            >
              <CardContent>
                <Typography variant="h6" gutterBottom color="text.primary" sx={{ fontWeight: 600 }}>
                  Sécurité
                </Typography>
                <Button
                  fullWidth
                  variant="contained"
                  onClick={() => setPasswordDialogOpen(true)}
                  sx={{
                    mt: 1,
                    py: 1.5,
                    borderRadius: '12px',
                    textTransform: 'none',
                    fontWeight: 'bold',
                  }}
                >
                  Modifier le mot de passe
                </Button>
              </CardContent>
            </Card>
          </Grid>

          {/* Déconnexion */}
          <Grid item xs={12} md={6}>
            <Card
              sx={{
                borderRadius: 2,
                boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.05)',
              }}
            >
              <CardContent>
                <Typography variant="h6" gutterBottom color="text.primary" sx={{ fontWeight: 600 }}>
                  Déconnexion
                </Typography>
                <Button
                  fullWidth
                  variant="contained"
                  color="error"
                  onClick={handleLogout}
                  sx={{
                    mt: 1,
                    py: 1.5,
                    borderRadius: '12px',
                    textTransform: 'none',
                    fontWeight: 'bold',
                  }}
                >
                  Se déconnecter
                </Button>
              </CardContent>
            </Card>
          </Grid>
        </Grid>

          {/* Dialogs */}
          <Dialog open={passwordDialogOpen} onClose={() => setPasswordDialogOpen(false)}>
            <DialogTitle>Modifier le mot de passe</DialogTitle>
            <DialogContent>
              <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, pt: 2 }}>
                <TextField
                  fullWidth
                  label="Mot de passe actuel"
                  type="password"
                  value={currentPassword}
                  onChange={(e) => setCurrentPassword(e.target.value)}
                />
                <TextField
                  fullWidth
                  label="Nouveau mot de passe"
                  type="password"
                  value={newPassword}
                  onChange={(e) => setNewPassword(e.target.value)}
                />
              </Box>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => setPasswordDialogOpen(false)}>Annuler</Button>
              <Button onClick={handleUpdatePassword} variant="contained">
                Confirmer
              </Button>
            </DialogActions>
          </Dialog>

          <Dialog open={emailDialogOpen} onClose={() => setEmailDialogOpen(false)}>
            <DialogTitle>Modifier l'email</DialogTitle>
            <DialogContent>
              <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, pt: 2 }}>
                <TextField
                  fullWidth
                  label="Nouvel email"
                  value={newEmail}
                  onChange={(e) => setNewEmail(e.target.value)}
                />
                <TextField
                  fullWidth
                  label="Mot de passe actuel"
                  type="password"
                  value={currentPassword}
                  onChange={(e) => setCurrentPassword(e.target.value)}
                />
              </Box>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => setEmailDialogOpen(false)}>Annuler</Button>
              <Button onClick={handleUpdateEmail} variant="contained">
                Confirmer
              </Button>
            </DialogActions>
          </Dialog>

          <Dialog open={addressDialogOpen} onClose={() => setAddressDialogOpen(false)}>
            <DialogTitle>
              {selectedAddress ? "Confirmer l'adresse" : 'Rechercher une adresse'}
            </DialogTitle>
            <DialogContent>
              <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, pt: 2 }}>
                <RadioGroup
                  value={addressType}
                  onChange={(e) => setAddressType(e.target.value)}
                  row
                >
                  <FormControlLabel
                    value="particulier"
                    control={<Radio />}
                    label="Particulier"
                  />
                  <FormControlLabel
                    value="professionnel"
                    control={<Radio />}
                    label="Professionnel"
                  />
                </RadioGroup>

                {addressType === 'professionnel' && (
                  <TextField
                    fullWidth
                    label="Nom de l'entreprise"
                    value={companyName}
                    onChange={(e) => setCompanyName(e.target.value)}
                  />
                )}

                {!selectedAddress ? (
                  <StandaloneSearchBox
                    onLoad={onLoad}
                    onPlacesChanged={onPlacesChanged}
                  >
                    <TextField
                      fullWidth
                      inputRef={inputRef}
                      placeholder="Rechercher une adresse"
                    />
                  </StandaloneSearchBox>
                ) : (
                  <>
                    <TextField
                      fullWidth
                      label="Adresse"
                      value={selectedAddress.addressLine}
                      disabled
                    />
                    <TextField
                      fullWidth
                      label="Code postal"
                      value={selectedAddress.postalCode}
                      disabled
                    />
                    <TextField
                      fullWidth
                      label="Ville"
                      value={selectedAddress.city}
                      disabled
                    />
                    <TextField
                      fullWidth
                      label="Pays"
                      value={selectedAddress.country}
                      disabled
                    />
                  </>
                )}
              </Box>
            </DialogContent>
            <DialogActions>
              {selectedAddress ? (
                <>
                  <Button onClick={() => setSelectedAddress(null)}>
                    Modifier l'adresse
                  </Button>
                  <Button onClick={handleSaveAddress} variant="contained">
                    Confirmer
                  </Button>
                </>
              ) : (
                <Button onClick={() => setAddressDialogOpen(false)}>
                  Annuler
                </Button>
              )}
            </DialogActions>
          </Dialog>

          {/* Snackbar */}
        <Snackbar
          open={snackbar.open}
          autoHideDuration={6000}
          onClose={handleCloseSnackbar}
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        >
          <Alert
            elevation={6}
            variant="filled"
            onClose={handleCloseSnackbar}
            severity={snackbar.severity}
            sx={{ borderRadius: '12px' }}
          >
            {snackbar.message}
          </Alert>
        </Snackbar>
      </Container>
    </>
  );
}

export default Profile;