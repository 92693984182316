import React, { useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Container, Box, Typography, TextField, Button } from '@mui/material';

function ResetPassword() {
  const { token } = useParams();
  const navigate = useNavigate();
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [message, setMessage] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (password !== confirmPassword) {
      setMessage("Les mots de passe ne correspondent pas.");
      return;
    }

    try {
      // Utilisation de la variable d'environnement pour l'URL de l'API
      const response = await fetch(`${process.env.REACT_APP_API_URL}/users/reset-password/${token}`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ password }),
      });

      if (response.ok) {
        setMessage("Mot de passe réinitialisé avec succès !");
        setTimeout(() => navigate('/login'), 3000);
      } else {
        setMessage("Le lien de réinitialisation est invalide ou a expiré.");
      }
    } catch (error) {
      setMessage("Erreur de connexion au serveur.");
    }
  };

  return (
    <Container maxWidth="sm">
      <Box sx={{ mt: 8, mb: 6, textAlign: 'center' }}>
        <Typography variant="h4" component="h1" gutterBottom>
          Réinitialisation du Mot de Passe
        </Typography>
        <form onSubmit={handleSubmit}>
          <TextField
            fullWidth
            type="password"
            label="Nouveau Mot de Passe"
            variant="outlined"
            margin="normal"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
            sx={{ mb: 2 }}
          />
          <TextField
            fullWidth
            type="password"
            label="Confirmer le Nouveau Mot de Passe"
            variant="outlined"
            margin="normal"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            required
            sx={{ mb: 4 }}
          />
          <Button 
            type="submit" 
            variant="contained" 
            color="primary" 
            sx={{ 
              py: 1.5, 
              px: 3, 
              fontSize: '1rem', 
              textTransform: 'none', 
              fontWeight: 'bold',
              borderRadius: 3,
            }}
          >
            Réinitialiser le Mot de Passe
          </Button>
        </form>
        {message && (
          <Typography variant="body2" color="textPrimary" sx={{ mt: 3 }}>
            {message}
          </Typography>
        )}
      </Box>
    </Container>
  );
}

export default ResetPassword;
