// src/theme.js
import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    primary: { main: '#E9404A' },
    secondary: { main: '#4A90E2' },
    background: {
      default: '#F9FBFA',
      paper: '#FFFFFF',
    },
    text: {
      primary: '#333333',
      secondary: '#666666',
    },
  },
  typography: {
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    h5: { fontWeight: 700 },
    body1: { fontSize: '1rem' },
  },
  shape: {
    borderRadius: 16,
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          color: '#ffffff',
          textTransform: 'none',
          borderRadius: 8,
          padding: '8px 16px',
          boxShadow: 'none',
          '&:hover': {
            boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)',
          },
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          backgroundColor: '#FFFFFF',
          borderRadius: 8,
          '& .MuiOutlinedInput-root': {
            '& fieldset': {
              borderColor: '#ddd',
            },
            '&:hover fieldset': {
              borderColor: '#E9404A',
            },
            '&.Mui-focused fieldset': {
              borderColor: '#E9404A',
              boxShadow: '0px 0px 4px rgba(233, 64, 74, 0.3)',
            },
          },
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          padding: '20px',
          borderRadius: 16,
          boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)',
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        head: {
          color: '#333333', // Couleur par défaut pour l'entête
          fontWeight: 600,
          fontSize: '1rem',
        },
        root: {
          padding: '12px',
        },
      },
    },
    MuiCssBaseline: {
      styleOverrides: {
        // React DatePicker styles
        '.react-datepicker': {
          position: 'absolute',
          zIndex: 1000, /* Assurez-vous que le calendrier est au-dessus des autres éléments */
          boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
          borderRadius: '8px',
        },
        '.react-datepicker__day': {
          color: '#333333',
          fontSize: '0.875rem',
          '&:hover': {
            backgroundColor: '#E9404A',
            color: '#FFFFFF',
          },
        },
        '.react-datepicker__day--valid': {
          backgroundColor: '#E6F4EA',
          color: '#000',
          borderRadius: '50%',
        },
        '.react-datepicker__day--valid:hover': {
          backgroundColor: '#B2E8C3',
        },
        '.react-datepicker__day--selected': {
          backgroundColor: '#E9404A',
          color: '#FFFFFF',
          borderRadius: '50%',
        },
        '.react-datepicker__day--keyboard-selected': {
          backgroundColor: '#E9404A',
          color: '#FFFFFF',
          borderRadius: '50%',
        },
        '.react-datepicker__day--outside-month': {
          color: '#CCC',
        },
        '.react-datepicker__header': {
          backgroundColor: '#F9FBFA',
          borderBottom: '1px solid #ddd',
          color: '#333333',
        },
        '.react-datepicker__current-month': {
          fontSize: '1rem',
          fontWeight: 700,
        },
        '.react-datepicker__day-names': {
          fontSize: '0.875rem',
          color: '#666666',
        },
        '.react-datepicker__triangle': {
          display: 'none',
        },
      },
    },
  },
});

export default theme;
