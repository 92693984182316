// src/components/admin/AdminWarrantyManagement.js
import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import {
  Container,
  Typography,
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Snackbar,
  Alert,
  TextField,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
  InputAdornment,
  TablePagination,
} from '@mui/material';
import { UploadFile, Search } from '@mui/icons-material';
import * as XLSX from 'xlsx';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import IconButton from '@mui/material/IconButton';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';


const AdminWarrantyManagement = () => {
  const [warranties, setWarranties] = useState([]);
  const [filteredWarranties, setFilteredWarranties] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [statusFilter, setStatusFilter] = useState('');
  const [openPreviewDialog, setOpenPreviewDialog] = useState(false);
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    severity: 'success',
  });
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const navigate = useNavigate();

  const API_URL = process.env.REACT_APP_API_URL; 


  useEffect(() => {
    const fetchWarranties = async () => {
      try {
        const token = localStorage.getItem('adminToken');
        const response = await fetch(`${API_URL}/admin/warranties`, {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        });

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();
        setWarranties(data);
        setFilteredWarranties(data);
      } catch (error) {
        console.error('Error fetching warranties:', error);
        setSnackbar({ open: true, message: 'Error fetching warranties', severity: 'error' });
      }
    };

    fetchWarranties();
  }, []);

  const handleFileChange = async (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();

    reader.onload = async (event) => {
      const data = event.target.result;
      const workbook = XLSX.read(data, { type: 'binary' });

      const sheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[sheetName];
      const json = XLSX.utils.sheet_to_json(worksheet);

      const formattedData = json.map((item) => ({
        ...item,
        warrantyStartDate: moment(item.warrantyStartDate, 'YYYY-MM-DD').format('DD/MM/YYYY'),
        warrantyEndDate: moment(item.warrantyEndDate, 'YYYY-MM-DD').format('DD/MM/YYYY'),
      }));

      setWarranties(formattedData);
      setOpenPreviewDialog(true);
    };

    reader.readAsBinaryString(file);
  };

  const handleConfirmImport = async () => {
    try {
      const token = localStorage.getItem('adminToken');
      const response = await fetch(`${API_URL}/admin/warranties/import`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ warranties }),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      setSnackbar({ open: true, message: 'Warranties imported successfully', severity: 'success' });
      setOpenPreviewDialog(false);
      setFilteredWarranties(warranties);
    } catch (error) {
      console.error('Error importing warranties:', error);
      setSnackbar({ open: true, message: 'Error importing warranties', severity: 'error' });
    }
  };

  const handleSearchChange = (e) => {
    const value = e.target.value;
    setSearchTerm(value);
    filterWarranties(value, statusFilter);
  };

  const handleStatusFilterChange = (e) => {
    const value = e.target.value;
    setStatusFilter(value);
    filterWarranties(searchTerm, value);
  };

  const filterWarranties = (searchTerm, status) => {
    let filtered = warranties;

    if (status) {
      filtered = filtered.filter((warranty) => warranty.status === status);
    }

    if (searchTerm) {
      filtered = filtered.filter((warranty) =>
        warranty.warrantyNumber?.toLowerCase().includes(searchTerm.toLowerCase()) ||
        warranty.productRef?.toLowerCase().includes(searchTerm.toLowerCase()) ||
        warranty.productName?.toLowerCase().includes(searchTerm.toLowerCase())
      );
    }

    setFilteredWarranties(filtered);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleViewDetails = (id) => {
    navigate(`/admin/warranty/${id}`);
  };

  return (
    <Box sx={{ display: 'flex' }}>
      <Helmet>
        <title>Gestion des Garanties - Admin</title>
        <meta name="description" content="Page de gestion des garanties pour l'administrateur. Importer, filtrer et visualiser les détails des garanties." />
        <meta name="keywords" content="admin, gestion des garanties, importer garantie, filtrer garantie, détail garantie" />
      </Helmet>
      <Container>
        <Box my={4}>
          <Typography variant="h4" component="h1" gutterBottom>
            Gestion des Garanties
          </Typography>
          <Button
            variant="contained"
            component="label"
            color="primary"
            startIcon={<UploadFile />}
            sx={{ mb: 2 }}
          >
            Importer un fichier
            <input type="file" hidden accept=".csv, .xlsx" onChange={handleFileChange} />
          </Button>
          <Box sx={{ mb: 2, display: 'flex', gap: 2 }}>
            <TextField
              label="Rechercher"
              variant="outlined"
              value={searchTerm}
              onChange={handleSearchChange}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Search />
                  </InputAdornment>
                ),
              }}
              fullWidth
            />
            <FormControl variant="outlined" fullWidth>
              <InputLabel>Statut</InputLabel>
              <Select
                value={statusFilter}
                onChange={handleStatusFilterChange}
                label="Statut"
              >
                <MenuItem value="">
                  <em>Tous</em>
                </MenuItem>
                <MenuItem value="active">Active</MenuItem>
                <MenuItem value="expired">Expirée</MenuItem>
              </Select>
            </FormControl>
          </Box>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Numéro de Garantie</TableCell>
                  <TableCell>Produit Réf</TableCell>
                  <TableCell>Nom du Produit</TableCell>
                  <TableCell>Date de Début</TableCell>
                  <TableCell>Date de Fin</TableCell>
                  <TableCell>Statut</TableCell>
                  <TableCell>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredWarranties.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((warranty) => (
                  <TableRow key={warranty._id}>
                    <TableCell>{warranty.warrantyNumber || '-'}</TableCell>
                    <TableCell>{warranty.productRef || '-'}</TableCell>
                    <TableCell>{warranty.productName || '-'}</TableCell>
                    <TableCell>{warranty.warrantyStartDate || '-'}</TableCell>
                    <TableCell>{warranty.warrantyEndDate || '-'}</TableCell>
                    <TableCell>{warranty.status || '-'}</TableCell>
                    <TableCell>
                      {/* Remplacement du bouton pour utiliser un style similaire à celui de UserWarranties */}
                      <IconButton
                        color="primary"
                        onClick={() => handleViewDetails(warranty.warrantyNumber)}
                      >
                        <InfoOutlinedIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={filteredWarranties.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Box>

        {/* Preview Dialog */}
        <Dialog open={openPreviewDialog} onClose={() => setOpenPreviewDialog(false)}>
          <DialogTitle>Prévisualisation des Données Importées</DialogTitle>
          <DialogContent>
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Numéro de Garantie</TableCell>
                    <TableCell>Produit Réf</TableCell>
                    <TableCell>Nom du Produit</TableCell>
                    <TableCell>Date de Début</TableCell>
                    <TableCell>Date de Fin</TableCell>
                    <TableCell>Statut</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {warranties.map((warranty, index) => (
                    <TableRow key={index}>
                      <TableCell>{warranty.warrantyNumber || '-'}</TableCell>
                      <TableCell>{warranty.productRef || '-'}</TableCell>
                      <TableCell>{warranty.productName || '-'}</TableCell>
                      <TableCell>{warranty.warrantyStartDate || '-'}</TableCell>
                      <TableCell>{warranty.warrantyEndDate || '-'}</TableCell>
                      <TableCell>{warranty.status || '-'}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setOpenPreviewDialog(false)}>Annuler</Button>
            <Button onClick={handleConfirmImport} color="primary">
              Confirmer l'importation
            </Button>
          </DialogActions>
        </Dialog>

        <Snackbar
          open={snackbar.open}
          autoHideDuration={6000}
          onClose={() => setSnackbar({ ...snackbar, open: false })}
        >
          <Alert
            onClose={() => setSnackbar({ ...snackbar, open: false })}
            severity={snackbar.severity}
            sx={{ width: '100%' }}
          >
            {snackbar.message}
          </Alert>
        </Snackbar>
      </Container>
    </Box>
  );
};

export default AdminWarrantyManagement;
