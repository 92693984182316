import React from 'react';
import {
  AppBar,
  Toolbar,
  Box,
  IconButton,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import logo from '../assets/images/hanger-logo.png';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';

function Header() {
  const navigate = useNavigate();
  const token = localStorage.getItem('token');

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const handleLogoClick = () => {
    if (token) {
      navigate('/user-warranties');
    } else {
      navigate('/');
    }
  };

  const handleProfileClick = () => {
    navigate('/profile');
  };

  return (
    <AppBar
      position="sticky"
      sx={{
        backgroundColor: '#ffffff',
        color: '#000000',
        boxShadow: 'none',
        borderBottom: '2px solid #e0e0e0',
        zIndex: (theme) => theme.zIndex.drawer + 1,
      }}
    >
      <Toolbar
        sx={{
          justifyContent: 'center',
          alignItems: 'center',
          position: 'relative',
          minHeight: { xs: 56, sm: 64 },
        }}
      >
        {/* Logo centré */}
        <Box
          onClick={handleLogoClick}
          sx={{
            cursor: 'pointer',
            textDecoration: 'none',
            display: 'flex',
            alignItems: 'center',
            position: 'absolute',
            left: '50%',
            transform: 'translateX(-50%)',
          }}
        >
          <img
            src={logo}
            alt="Hanger Logo"
            style={{ height: isMobile ? 40 : 60 }}
          />
        </Box>

        {/* Icône Profil à droite */}
        {token && (
          <IconButton
            onClick={handleProfileClick}
            color="inherit"
            sx={{
              position: 'absolute',
              right: theme.spacing(2),
            }}
            aria-label="Profil"
          >
            <AccountCircleIcon fontSize="large" />
          </IconButton>
        )}
      </Toolbar>
    </AppBar>
  );
}

export default Header;
