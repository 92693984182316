import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import {
  Container,
  Typography,
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  DialogActions,
  Snackbar,
  Alert,
  TablePagination,
} from '@mui/material';
import { Edit, Delete, Add, Visibility } from '@mui/icons-material';

const AdminComboManagement = () => {
  const [combos, setCombos] = useState([]);
  const [products, setProducts] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedCombo, setSelectedCombo] = useState(null);
  const [formData, setFormData] = useState({
    name: '',
    storeCode: '',
    hangerModel: '',
    items: [],
    imageUrl: '',
  });
  const [detailDialogOpen, setDetailDialogOpen] = useState(false);
  const [detailCombo, setDetailCombo] = useState(null);
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    severity: 'success',
  });
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const API_URL = process.env.REACT_APP_API_URL;

  useEffect(() => {
    const fetchCombos = async () => {
      try {
        const token = localStorage.getItem('adminToken');
        const response = await fetch(`${API_URL}/combos`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        if (response.ok) setCombos(await response.json());
        else throw new Error('Erreur lors de la récupération des combos');
      } catch (error) {
        console.error(error);
      }
    };

    const fetchProducts = async () => {
      try {
        const token = localStorage.getItem('adminToken');
        const response = await fetch(`${API_URL}/products`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        if (response.ok) setProducts(await response.json());
        else throw new Error('Erreur lors de la récupération des produits');
      } catch (error) {
        console.error(error);
      }
    };

    fetchCombos();
    fetchProducts();
  }, [API_URL]);

  const showSnackbar = (message, severity = 'success') =>
    setSnackbar({ open: true, message, severity });

  const handleOpenDialog = async (combo) => {
    if (combo) {
      try {
        const token = localStorage.getItem('adminToken');
        const response = await fetch(`${API_URL}/combos/${combo._id}`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        if (response.ok) {
          const data = await response.json();
  
          // Map items and set formData
          const mappedItems = data.items.map((item) => ({
            productId: item.productId._id,
            quantity: item.quantity,
            productDetails: item.productId,
          }));
  
          setFormData({
            name: data.name,
            storeCode: data.storeCode,
            hangerModel: data.hangerModel || '', // Ensure hangerModel is included
            items: mappedItems,
            imageUrl: data.imageUrl || '',
          });
        } else {
          console.error('Erreur lors de la récupération des détails du combo');
        }
      } catch (error) {
        console.error('Erreur lors du chargement des détails du combo:', error);
      }
    } else {
      setFormData({ name: '', storeCode: '', hangerModel: '', items: [], imageUrl: '' });
    }
    setSelectedCombo(combo);
    setOpenDialog(true);
  };
  
  

  const handleViewDetails = (combo) => {
    setDetailCombo(combo); 
    setDetailDialogOpen(true);
  };

  const handleSaveCombo = async () => {
    try {
      if (
        !formData.name.trim() ||
        !formData.storeCode.trim() ||
        !formData.hangerModel.trim() ||
        formData.items.length === 0
      ) {
        showSnackbar('Tous les champs obligatoires doivent être remplis.', 'error');
        return;
      }
  
      const token = localStorage.getItem('adminToken');
      const url = selectedCombo
        ? `${API_URL}/combos/${selectedCombo._id}`
        : `${API_URL}/combos`;
      const method = selectedCombo ? 'PUT' : 'POST';
  
      const response = await fetch(url, {
        method,
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });
  
      if (response.ok) {
        const updatedCombo = await response.json();
        setCombos((prev) =>
          selectedCombo
            ? prev.map((combo) =>
                combo._id === updatedCombo._id ? updatedCombo : combo
              )
            : [...prev, updatedCombo]
        );
        showSnackbar(selectedCombo ? 'Combo mis à jour' : 'Combo ajouté');
        handleCloseDialog();
      } else {
        const errorResponse = await response.json();
        throw new Error(errorResponse.message || 'Erreur lors de la sauvegarde');
      }
    } catch (error) {
      console.error(error);
      showSnackbar(error.message || 'Erreur lors de la sauvegarde du combo', 'error');
    }
  };
  

  const handleDeleteCombo = async (id) => {
    if (window.confirm('Êtes-vous sûr de vouloir supprimer ce combo ?')) {
      try {
        const token = localStorage.getItem('adminToken');
        const response = await fetch(`${API_URL}/combos/${id}`, {
          method: 'DELETE',
          headers: { Authorization: `Bearer ${token}` },
        });
        if (response.ok) {
          setCombos((prev) => prev.filter((combo) => combo._id !== id));
          showSnackbar('Combo supprimé avec succès');
        } else throw new Error('Erreur lors de la suppression');
      } catch (error) {
        console.error(error);
        showSnackbar('Erreur lors de la suppression du combo', 'error');
      }
    }
  };

  const handleAddProductToCombo = () => {
    setFormData((prev) => ({
      ...prev,
      items: [...prev.items, { productId: '', quantity: 1 }], // Ajout d'un item par défaut
    }));
  };

  const handleRemoveProductFromCombo = (index) => {
    setFormData((prev) => ({
      ...prev,
      items: prev.items.filter((_, i) => i !== index),
    }));
  };

  const handleCloseDialog = () => {
    setSelectedCombo(null);
    setOpenDialog(false);
  };
  

  return (
    <Container>
      <Helmet>
        <title>Gestion des Combos - Admin</title>
      </Helmet>
      <Box my={4}>
        <Typography variant="h4">Gestion des Combos</Typography>
        <Button
          variant="contained"
          color="primary"
          sx={{ mb: 2 }}
          startIcon={<Add />}
          onClick={() => handleOpenDialog(null)}
        >
          Ajouter un Combo
        </Button>
        <TableContainer component={Paper}>
          <Table>
          <TableHead>
            <TableRow>
              <TableCell>Image</TableCell>
              <TableCell>Nom</TableCell>
              <TableCell>Code Magasin</TableCell>
              <TableCell>Hanger Model</TableCell> {/* Nouvelle colonne */}
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {combos
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((combo) => (
                <TableRow key={combo._id}>
                  <TableCell>
                    <img
                      src={combo.imageUrl || '/public/default-product-image.png'}
                      alt="Combo"
                      style={{ width: 100, height: 100, objectFit: 'cover' }}
                    />
                  </TableCell>
                  <TableCell>{combo.name}</TableCell>
                  <TableCell>{combo.storeCode}</TableCell>
                  <TableCell>{combo.hangerModel}</TableCell> {/* Affichage du champ */}
                  <TableCell>
                    <IconButton color="info" onClick={() => handleViewDetails(combo)}>
                      <Visibility />
                      </IconButton>
                    <IconButton color="primary" onClick={() => handleOpenDialog(combo)}>
                      <Edit />
                    </IconButton>
                    <IconButton color="error" onClick={() => handleDeleteCombo(combo._id)}>
                      <Delete />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>

          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={combos.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={(e, newPage) => setPage(newPage)}
          onRowsPerPageChange={(e) => {
            setRowsPerPage(parseInt(e.target.value, 10));
            setPage(0);
          }}
        />
        <Dialog open={openDialog} onClose={handleCloseDialog}>
          <DialogTitle>{selectedCombo ? 'Modifier Combo' : 'Ajouter un Combo'}</DialogTitle>
          <DialogContent>
            <TextField
              margin="dense"
              label="Nom"
              fullWidth
              value={formData.name}
              onChange={(e) => setFormData({ ...formData, name: e.target.value })}
            />
            <TextField
              margin="dense"
              label="Hanger Model"
              fullWidth
              value={formData.hangerModel}
              onChange={(e) => setFormData({ ...formData, hangerModel: e.target.value })}
            />
            <TextField
              margin="dense"
              label="Code Magasin"
              fullWidth
              value={formData.storeCode}
              onChange={(e) => setFormData({ ...formData, storeCode: e.target.value })}
            />
            <TextField
              margin="dense"
              label="URL de l'image"
              fullWidth
              value={formData.imageUrl}
              onChange={(e) => setFormData({ ...formData, imageUrl: e.target.value })}
            />
            <Typography variant="h6" sx={{ mt: 2, mb: 1 }}>
              Produits dans le Combo
            </Typography>
            {formData.items.map((item, index) => {
                const selectedProduct =
                  item.productDetails || products.find((product) => product._id === item.productId);

                return (
                  <Box key={index} sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                    {/* Thumbnail */}
                    <img
                      src={selectedProduct?.imageUrl || '/public/default-product-image.png'}
                      alt={selectedProduct?.model || 'Produit'}
                      style={{
                        width: 50,
                        height: 50,
                        objectFit: 'cover',
                        marginRight: 10,
                      }}
                    />
                    {/* Sélecteur de produit */}
                    <TextField
                      select
                      SelectProps={{ native: true }}
                      margin="dense"
                      label="Produit"
                      fullWidth
                      value={item.productId}
                      onChange={(e) => {
                        const updatedItems = [...formData.items];
                        updatedItems[index].productId = e.target.value;
                        setFormData({ ...formData, items: updatedItems });
                      }}
                    >
                      <option value="">-- Sélectionner un produit --</option>
                      {products.map((product) => (
                        <option key={product._id} value={product._id}>
                          {`${product.model} (Ref: ${product.productNumber})`}
                        </option>
                      ))}
                    </TextField>
                    {/* Quantité */}
                    <TextField
                      margin="dense"
                      label="Quantité"
                      type="number"
                      sx={{ ml: 2, width: '100px' }}
                      value={item.quantity}
                      onChange={(e) => {
                        const updatedItems = [...formData.items];
                        updatedItems[index].quantity = parseInt(e.target.value, 10) || 1;
                        setFormData({ ...formData, items: updatedItems });
                      }}
                    />
                    {/* Bouton Supprimer */}
                    <Button
                      variant="contained"
                      color="error"
                      sx={{ ml: 2 }}
                      onClick={() => handleRemoveProductFromCombo(index)}
                    >
                      Supprimer
                    </Button>
                  </Box>
                );
              })}
            <Button
              variant="contained"
              color="primary"
              sx={{ mt: 2 }}
              onClick={handleAddProductToCombo}
            >
              Ajouter un produit
            </Button>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseDialog} color="secondary">
              Annuler
            </Button>
            <Button onClick={handleSaveCombo} color="primary" variant="contained">
              Sauvegarder
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog open={detailDialogOpen} onClose={() => setDetailDialogOpen(false)}>
            <DialogTitle>Détails du Combo</DialogTitle>
            <DialogContent>
              {detailCombo && (
                <>
                  {/* Image du Combo */}
                  <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2 }}>
                    <img
                      src={detailCombo.imageUrl || '/public/default-product-image.png'}
                      alt={detailCombo.name}
                      style={{
                        width: '100%',
                        maxWidth: 300,
                        height: 'auto',
                        borderRadius: 8,
                        objectFit: 'cover',
                        border: '1px solid #ccc',
                      }}
                    />
                  </Box>
                  {/* Détails du Combo */}
                  <Typography variant="h6">Nom : {detailCombo.name}</Typography>
                  <Typography>Code Magasin : {detailCombo.storeCode}</Typography>
                  <Typography>Hanger Model : {detailCombo.hangerModel}</Typography> {/* Nouveau champ */}
                  <Typography variant="h6" sx={{ mt: 2 }}>
                    Produits :
                  </Typography>
                  <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, mt: 2 }}>
                    {detailCombo.items.map((item, index) => (
                      <Box
                        key={index}
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          gap: 2,
                          p: 1,
                          border: '1px solid #ccc',
                          borderRadius: 2,
                        }}
                      >
                        <img
                          src={item.productId.imageUrl || '/public/default-product-image.png'}
                          alt={item.productId.model}
                          style={{
                            width: 80,
                            height: 80,
                            objectFit: 'cover',
                            borderRadius: 4,
                          }}
                        />
                        <Box>
                          <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
                            {item.productId.model}
                          </Typography>
                          <Typography variant="body2" color="textSecondary">
                            Référence : {item.productId.productNumber}
                          </Typography>
                          <Typography variant="body2" color="textSecondary">
                            Quantité : {item.quantity}
                          </Typography>
                        </Box>
                      </Box>
                    ))}
                  </Box>
                </>
              )}
            </DialogContent>
            <DialogActions>
              <Button onClick={() => setDetailDialogOpen(false)} color="secondary">
                Fermer
              </Button>
            </DialogActions>
          </Dialog>


      </Box>
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={() => setSnackbar({ ...snackbar, open: false })}
      >
        <Alert
          onClose={() => setSnackbar({ ...snackbar, open: false })}
          severity={snackbar.severity}
          sx={{ width: '100%' }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default AdminComboManagement;
