import React, { useState } from 'react';
import {
  Container,
  Box,
  TextField,
  Typography,
  Button,
  InputAdornment,
  IconButton,
  Grid,
  Checkbox,
  FormControlLabel,
  Divider,
  CircularProgress,
  Link,
  useTheme,
  useMediaQuery,
} from '@mui/material';
import { Visibility, VisibilityOff, CheckCircle, Cancel } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';

function Register() {
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    password: '',
    optIn: false,
  });
  const [showPassword, setShowPassword] = useState(false);
  const [passwordValidation, setPasswordValidation] = useState({
    length: false,
    uppercase: false,
    number: false,
    specialChar: false,
  });
  const [loading, setLoading] = useState(false);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === 'checkbox' ? checked : value,
    });

    if (name === 'password') {
      setPasswordValidation({
        length: value.length >= 8,
        uppercase: /[A-Z]/.test(value),
        number: /[0-9]/.test(value),
        specialChar: /[!@#$%^&*(),.?":{}|<>]/.test(value),
      });
    }
  };

  const handleSubmit = async () => {
    const { length, uppercase, number, specialChar } = passwordValidation;

    if (!formData.firstName || !formData.lastName || !formData.email || !formData.password) {
      alert('Veuillez remplir tous les champs.');
      return;
    }

    if (!length || !uppercase || !number || !specialChar) {
      alert('Votre mot de passe ne respecte pas les règles de sécurité.');
      return;
    }

    if (!formData.optIn) {
      alert('Veuillez accepter les conditions générales.');
      return;
    }

    setLoading(true);

    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/users/register`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });

      const data = await response.json();

      if (response.ok) {
        navigate('/register-confirmation');
      } else {
        alert(data.message);
      }
    } catch (error) {
      alert('Une erreur est survenue. Veuillez réessayer.');
    } finally {
      setLoading(false);
    }
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <Container maxWidth="sm" sx={{ p: { xs: 2, sm: 3 } }}>
      <Box
        sx={{
          mt: { xs: 4, sm: 8 },
          mb: { xs: 4, sm: 6 },
          textAlign: 'center',
        }}
      >
        <Typography
          variant={isMobile ? 'h5' : 'h4'}
          component="h1"
          sx={{ fontWeight: 700, mb: 1 }}
        >
          Inscription
        </Typography>
        <Typography
          variant="body2"
          color="text.secondary"
          sx={{ mt: 1, fontStyle: 'italic', px: { xs: 1, sm: 0 } }}
        >
          Créez un compte pour enregistrer vos outils et garantir leur suivi.
        </Typography>
      </Box>
      <Divider sx={{ my: { xs: 2, sm: 3 } }} />
      <TextField
        fullWidth
        name="firstName"
        label="Prénom"
        variant="outlined"
        margin="normal"
        value={formData.firstName}
        onChange={handleChange}
        sx={{
          '& .MuiOutlinedInput-root': {
            borderRadius: '12px',
          },
        }}
      />
      <TextField
        fullWidth
        name="lastName"
        label="Nom"
        variant="outlined"
        margin="normal"
        value={formData.lastName}
        onChange={handleChange}
        sx={{
          '& .MuiOutlinedInput-root': {
            borderRadius: '12px',
          },
        }}
      />
      <TextField
        fullWidth
        name="email"
        label="Adresse Email"
        variant="outlined"
        margin="normal"
        value={formData.email}
        onChange={handleChange}
        sx={{
          '& .MuiOutlinedInput-root': {
            borderRadius: '12px',
          },
        }}
      />
      <TextField
        fullWidth
        name="password"
        label="Mot de Passe"
        type={showPassword ? 'text' : 'password'}
        variant="outlined"
        margin="normal"
        value={formData.password}
        onChange={handleChange}
        sx={{
          '& .MuiOutlinedInput-root': {
            borderRadius: '12px',
          },
        }}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton onClick={togglePasswordVisibility}>
                {showPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
      {/* Feedback dynamique sur les règles de mot de passe */}
      <Box sx={{ mt: 2 }}>
        <Typography variant="subtitle1" gutterBottom>
          Votre mot de passe doit contenir :
        </Typography>
        <Grid container spacing={1}>
          {[
            {
              label: 'Au moins 8 caractères',
              valid: passwordValidation.length,
            },
            {
              label: 'Une lettre majuscule',
              valid: passwordValidation.uppercase,
            },
            {
              label: 'Un chiffre',
              valid: passwordValidation.number,
            },
            {
              label: 'Un caractère spécial (!@#$...)',
              valid: passwordValidation.specialChar,
            },
          ].map((item, index) => (
            <Grid item xs={12} sm={6} key={index}>
              <Typography
                variant="body2"
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  color: item.valid ? 'green' : 'red',
                }}
              >
                {item.valid ? (
                  <CheckCircle fontSize="small" />
                ) : (
                  <Cancel fontSize="small" />
                )}
                &nbsp;{item.label}
              </Typography>
            </Grid>
          ))}
        </Grid>
      </Box>
      <FormControlLabel
        control={
          <Checkbox
            name="optIn"
            checked={formData.optIn}
            onChange={handleChange}
            color="primary"
          />
        }
        label={
          <Typography variant="body2">
            J'accepte les{' '}
            <Link href="/terms" target="_blank" rel="noopener" color="primary">
              conditions générales d'utilisation
            </Link>
            .
          </Typography>
        }
        sx={{ mt: 3 }}
      />
      <Button
        variant="contained"
        color="primary"
        fullWidth
        sx={{
          mt: 4,
          py: 1.5,
          fontSize: '1rem',
          fontWeight: 'bold',
          textTransform: 'none',
          borderRadius: '12px',
        }}
        onClick={handleSubmit}
        disabled={loading}
      >
        {loading ? <CircularProgress size={24} sx={{ color: 'white' }} /> : "S'inscrire"}
      </Button>
      <Box sx={{ mb: { xs: 4, sm: 5 } }} />
    </Container>
  );
}

export default Register;
