import React, { useState } from 'react';
import {
  Container,
  Box,
  Typography,
  TextField,
  Button,
  useTheme,
  useMediaQuery,
  Alert,
} from '@mui/material';

function PasswordRecovery() {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [error, setError] = useState(false); // Pour déterminer le type de message (succès ou erreur)

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const handleSubmit = async (e) => {
    e.preventDefault();
    setMessage('');
    setError(false);
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/users/recover-password`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email }),
      });
      if (response.ok) {
        setMessage('Un email de récupération a été envoyé.');
        setError(false);
      } else {
        setMessage('Erreur lors de la tentative de récupération du mot de passe.');
        setError(true);
      }
    } catch (error) {
      setMessage('Erreur de connexion au serveur.');
      setError(true);
    }
  };

  return (
    <Container maxWidth="sm" sx={{ p: { xs: 2, sm: 3 } }}>
      <Box
        sx={{
          mt: { xs: 4, sm: 8 },
          mb: { xs: 6, sm: 10 },
          textAlign: 'center',
        }}
      >
        <Typography
          variant={isMobile ? 'h5' : 'h4'}
          component="h1"
          sx={{ fontWeight: 700, mb: 1 }}
        >
          Récupération de Mot de Passe
        </Typography>
        <Typography
          variant="body2"
          color="text.secondary"
          gutterBottom
          sx={{ px: { xs: 1, sm: 0 } }}
        >
          Veuillez entrer votre adresse email pour recevoir un lien de récupération de mot de passe.
        </Typography>
        <form onSubmit={handleSubmit}>
          <TextField
            fullWidth
            name="email"
            label="Adresse Email"
            variant="outlined"
            margin="normal"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            sx={{
              bgcolor: '#f4f6f8',
              borderRadius: 1,
              '& .MuiOutlinedInput-root': {
                borderRadius: '12px',
              },
            }}
            required
          />
          <Button
            type="submit"
            variant="contained"
            color="primary"
            sx={{
              mt: 3,
              width: '100%',
              py: 1.5,
              fontSize: '1rem',
              textTransform: 'none',
              fontWeight: 'bold',
              borderRadius: '12px',
            }}
          >
            Envoyer
          </Button>
        </form>
        {message && (
          <Alert
            severity={error ? 'error' : 'success'}
            sx={{
              mt: 3,
              fontSize: '0.95rem',
              textAlign: 'left',
            }}
          >
            {message}
          </Alert>
        )}
      </Box>
    </Container>
  );
}

export default PasswordRecovery;
