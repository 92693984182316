// src/components/Sidebar.js
import React from 'react';
import { Box, List, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import DashboardIcon from '@mui/icons-material/Dashboard';
import PeopleIcon from '@mui/icons-material/People';
import SettingsIcon from '@mui/icons-material/Settings';
import GavelIcon from '@mui/icons-material/Gavel';
import DevicesIcon from '@mui/icons-material/Devices';
import SecurityIcon from '@mui/icons-material/Security';
import StoreIcon from '@mui/icons-material/Store';
import { Link } from 'react-router-dom';
import { Inventory,Category } from '@mui/icons-material';

const Sidebar = () => {
  return (
    <Box
      sx={{
        width: 240,
        bgcolor: 'background.paper',
        position: 'sticky',
        top: 0,
        height: '100vh',
        boxShadow: 1,
        overflow: 'auto',
      }}
    >
      <List>
        <ListItem button component={Link} to="/admin/dashboard">
          <ListItemIcon><DashboardIcon /></ListItemIcon>
          <ListItemText primary="Dashboard" />
        </ListItem>
        <ListItem button component={Link} to="/admin/users">
          <ListItemIcon><PeopleIcon /></ListItemIcon>
          <ListItemText primary="Gestion des utilisateurs" />
        </ListItem>
        <ListItem button component={Link} to="/admin/warranties">
          <ListItemIcon><GavelIcon /></ListItemIcon>
          <ListItemText primary="Gestion des garanties" />
        </ListItem>
        <ListItem button component={Link} to="/admin/combos">
          <ListItemIcon><Category /></ListItemIcon>
          <ListItemText primary="Gestion des combos" />
        </ListItem>
        <ListItem button component={Link} to="/admin/products">
          <ListItemIcon><Inventory /></ListItemIcon>
          <ListItemText primary="Gestion des produits" />
        </ListItem>
        <ListItem button component={Link} to="/admin/serial-numbers">
          <ListItemIcon><SecurityIcon /></ListItemIcon>
          <ListItemText primary="Gestion des numéros de série" />
        </ListItem>
        <ListItem button component={Link} to="/admin/retailers">
          <ListItemIcon><StoreIcon /></ListItemIcon>
          <ListItemText primary="Gestion des revendeurs" />
        </ListItem>
        <ListItem button component={Link} to="/admin/settings">
          <ListItemIcon><SettingsIcon /></ListItemIcon>
          <ListItemText primary="Paramètres" />
        </ListItem>
      </List>
    </Box>
  );
};

export default Sidebar;
