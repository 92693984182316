import React, { useState, useEffect, useMemo } from 'react';
import { Helmet } from 'react-helmet';
import {
  Container,
  Typography,
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  DialogActions,
  Snackbar,
  Alert,
  MenuItem,
  Select,
  InputAdornment,
  TablePagination,
} from '@mui/material';
import { Edit, Delete, Search } from '@mui/icons-material';

const AdminProductManagement = () => {
  const [products, setProducts] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [formData, setFormData] = useState({
    model: '',
    category: '',
    productNumber: '',
    storeCode: '',
    imageUrl: '',
    warrantyPeriod: '',
  });
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    severity: 'success',
  });
  const [searchTerm, setSearchTerm] = useState('');
  const [categoryFilter, setCategoryFilter] = useState('');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const API_URL = process.env.REACT_APP_API_URL;

  // Fonction pour récupérer les produits
  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const token = localStorage.getItem('adminToken');
        const response = await fetch(`${API_URL}/products`, {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        });
  
        if (response.ok) {
          const data = await response.json();
          setProducts(data);
        } else {
          console.error('Erreur lors de la récupération des produits:', response.statusText);
          showSnackbar('Erreur lors de la récupération des produits', 'error');
        }
      } catch (error) {
        console.error('Erreur lors du chargement des produits:', error);
        showSnackbar('Erreur lors du chargement des produits', 'error');
      }
    };
  
    fetchProducts();
  }, [API_URL]);

  // Fonction pour afficher les messages de notification
  const showSnackbar = (message, severity = 'success') => {
    setSnackbar({ open: true, message, severity });
  };

  const handleOpenDialog = (product) => {
    setSelectedProduct(product);
    setFormData(
      product || {
        model: '',
        category: '',
        productNumber: '',
        storeCode: '',
        imageUrl: '',
        warrantyPeriod: '',
      }
    );
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setSelectedProduct(null);
    setOpenDialog(false);
  };

  const handleSaveProduct = async () => {
    try {
      const token = localStorage.getItem('adminToken');
      const url = selectedProduct 
        ? `${API_URL}/products/${selectedProduct._id}`
        : `${API_URL}/products`;
      const method = selectedProduct ? 'PUT' : 'POST';

      const response = await fetch(url, {
        method,
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });

      if (response.ok) {
        const updatedProduct = await response.json();
        if (selectedProduct) {
          setProducts((prevProducts) =>
            prevProducts.map((product) =>
              product._id === updatedProduct._id ? updatedProduct : product
            )
          );
          showSnackbar('Produit mis à jour avec succès');
        } else {
          setProducts((prevProducts) => [...prevProducts, updatedProduct]);
          showSnackbar('Produit ajouté avec succès');
        }
        handleCloseDialog();
      } else {
        throw new Error('Erreur lors de la sauvegarde du produit');
      }
    } catch (error) {
      console.error('Erreur lors de la sauvegarde du produit:', error);
      showSnackbar('Erreur lors de la sauvegarde du produit', 'error');
    }
  };

  const handleDeleteProduct = async (id) => {
    if (window.confirm('Êtes-vous sûr de vouloir supprimer ce produit ?')) {
      try {
        const token = localStorage.getItem('adminToken');
        const response = await fetch(`${API_URL}/products/${id}`, {
          method: 'DELETE',
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        if (response.ok) {
          setProducts((prevProducts) => prevProducts.filter((p) => p._id !== id));
          showSnackbar('Produit supprimé avec succès');
        } else {
          throw new Error('Erreur lors de la suppression du produit');
        }
      } catch (error) {
        console.error('Erreur:', error);
        showSnackbar('Erreur lors de la suppression du produit', 'error');
      }
    }
  };

  const filteredProducts = useMemo(() => {
    return products
      .filter((product) =>
        product.model.toLowerCase().includes(searchTerm.toLowerCase())
      )
      .filter((product) =>
        categoryFilter ? product.category === categoryFilter : true
      );
  }, [products, searchTerm, categoryFilter]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <Container>
      <Helmet>
        <title>Gestion des Produits - Admin</title>
      </Helmet>
      <Box my={4}>
        <Typography variant="h4">Gestion des Produits</Typography>
        <Box sx={{ display: 'flex', gap: 2, mb: 2 }}>
          <TextField
            label="Rechercher"
            variant="outlined"
            fullWidth
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Search />
                </InputAdornment>
              ),
            }}
          />
          <Select
            value={categoryFilter}
            onChange={(e) => setCategoryFilter(e.target.value)}
            displayEmpty
            fullWidth
          >
            <MenuItem value="">
              <em>Toutes les catégories</em>
            </MenuItem>
            {[...new Set(products.map((p) => p.category))].map((cat) => (
              <MenuItem key={cat} value={cat}>
                {cat}
              </MenuItem>
            ))}
          </Select>
        </Box>
        <Button
          variant="contained"
          color="primary"
          sx={{ mb: 2 }}
          onClick={() => handleOpenDialog(null)}
        >
          Ajouter un produit
        </Button>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Image</TableCell>
                <TableCell>Modèle</TableCell>
                <TableCell>Catégorie</TableCell>
                <TableCell>Code Magasin</TableCell> {/* Nouvelle colonne */}
                <TableCell>Référence</TableCell>
                <TableCell>Période de garantie</TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
            {filteredProducts.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((product) => (
              <TableRow key={product._id}>
                <TableCell>
                  {product.imageUrl ? (
                    <img
                      src={product.imageUrl}
                      alt={product.model}
                      style={{
                        width: '75px',
                        height: '75px',
                        objectFit: 'cover',
                        borderRadius: '4px',
                        boxShadow: '0 2px 4px rgba(0, 0, 0, 0.2)',
                      }}
                    />
                  ) : (
                    <Typography variant="body2" color="textSecondary">
                      Pas d'image
                    </Typography>
                  )}
                </TableCell>
                <TableCell>{product.model}</TableCell>
                <TableCell>{product.category}</TableCell>
                <TableCell>{product.storeCode}</TableCell> {/* Nouvelle donnée */}
                <TableCell>{product.productNumber}</TableCell>
                <TableCell>{product.warrantyPeriod} mois</TableCell>
                <TableCell>
                  <IconButton color="primary" onClick={() => handleOpenDialog(product)}>
                    <Edit />
                  </IconButton>
                  <IconButton color="error" onClick={() => handleDeleteProduct(product._id)}>
                    <Delete />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={filteredProducts.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
        <Dialog open={openDialog} onClose={handleCloseDialog}>
          <DialogTitle>{selectedProduct ? 'Modifier Produit' : 'Ajouter un Produit'}</DialogTitle>
          <DialogContent>
            <TextField
              margin="dense"
              name="model"
              label="Modèle"
              fullWidth
              value={formData.model || ''}
              onChange={(e) => setFormData({ ...formData, model: e.target.value })}
            />
            <TextField
              margin="dense"
              name="category"
              label="Catégorie"
              fullWidth
              value={formData.category || ''}
              onChange={(e) => setFormData({ ...formData, category: e.target.value })}
            />
            <TextField
              margin="dense"
              name="productNumber"
              label="Numéro de produit"
              fullWidth
              value={formData.productNumber || ''}
              onChange={(e) => setFormData({ ...formData, productNumber: e.target.value })}
            />
            <TextField
              margin="dense"
              name="storeCode"
              label="Code Magasin"
              fullWidth
              value={formData.storeCode || ''} // Ajout du champ storeCode
              onChange={(e) => setFormData({ ...formData, storeCode: e.target.value })}
            />
            <TextField
              margin="dense"
              name="imageUrl"
              label="URL de l'image"
              fullWidth
              value={formData.imageUrl || ''}
              onChange={(e) => setFormData({ ...formData, imageUrl: e.target.value })}
            />
            <TextField
              margin="dense"
              name="warrantyPeriod"
              label="Durée de garantie (en mois)"
              fullWidth
              value={formData.warrantyPeriod || ''}
              onChange={(e) => setFormData({ ...formData, warrantyPeriod: e.target.value })}
            />
          </DialogContent>
          <DialogActions>
            <Button
              onClick={handleCloseDialog}
              sx={{
                color: '#fff',
                backgroundColor: '#f44336', // Rouge pour le bouton "Annuler"
                '&:hover': {
                  backgroundColor: '#d32f2f', // Rouge foncé au survol
                },
              }}
            >
              Annuler
            </Button>
            <Button
              onClick={handleSaveProduct}
              sx={{
                color: '#fff',
                backgroundColor: '#4caf50', // Vert pour le bouton "Sauvegarder"
                '&:hover': {
                  backgroundColor: '#388e3c', // Vert foncé au survol
                },
              }}
            >
              Sauvegarder
            </Button>
          </DialogActions>
        </Dialog>
        <Snackbar
          open={snackbar.open}
          autoHideDuration={6000}
          onClose={() => setSnackbar({ ...snackbar, open: false })}
        >
          <Alert
            onClose={() => setSnackbar({ ...snackbar, open: false })}
            severity={snackbar.severity}
            sx={{ width: '100%' }}
          >
            {snackbar.message}
          </Alert>
        </Snackbar>
      </Box>
    </Container>
  );
};

export default AdminProductManagement;
