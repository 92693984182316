import React, { useState, useEffect, useCallback } from 'react';
import { Container, Typography, Box, Paper, Button, Dialog, DialogTitle, DialogContent, DialogActions, TextField } from '@mui/material';
import { Helmet } from 'react-helmet';

const AdminRetailerManagement = () => {
  const [retailers, setRetailers] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [editRetailer, setEditRetailer] = useState(null);
  const [error, setError] = useState(null);

  const API_URL = process.env.REACT_APP_API_URL;

  // Utiliser useCallback pour garantir une fonction stable
  const fetchRetailers = useCallback(async () => {
    try {
      const token = localStorage.getItem('adminToken');
      const response = await fetch(`${API_URL}/admin/retailers`, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      setRetailers(data);
    } catch (error) {
      console.error('Error fetching retailers:', error);
      setError('Impossible de récupérer les revendeurs. Vérifiez la connexion au serveur.');
    }
  }, [API_URL]);

  useEffect(() => {
    fetchRetailers();
  }, [fetchRetailers]);

  const handleOpenDialog = (retailer = null) => {
    setEditRetailer(retailer);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setEditRetailer(null);
    setOpenDialog(false);
  };

  const handleSaveRetailer = async () => {
    try {
      const token = localStorage.getItem('adminToken');
      const method = editRetailer?._id ? 'PUT' : 'POST';
      const url = editRetailer?._id
        ? `${API_URL}/admin/retailers/${editRetailer._id}`
        : `${API_URL}/admin/retailers`;

      const response = await fetch(url, {
        method,
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(editRetailer),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      handleCloseDialog();
      fetchRetailers(); // Réutilisation de fetchRetailers
    } catch (error) {
      console.error('Error saving retailer', error);
    }
  };

  const handleDeleteRetailer = async (id) => {
    try {
      const token = localStorage.getItem('adminToken');
      const response = await fetch(`${API_URL}/admin/retailers/${id}`, {
        method: 'DELETE',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      setRetailers(retailers.filter((retailer) => retailer._id !== id));
    } catch (error) {
      console.error('Error deleting retailer', error);
    }
  };

  return (
    <Container>
      <Helmet>
        <title>Gestion des Revendeurs - Admin</title>
        <meta name="description" content="Page d'administration pour gérer les revendeurs du site." />
      </Helmet>
      <Typography variant="h4" component="h1" gutterBottom>
        Gestion des Revendeurs
      </Typography>

      {error && (
        <Typography color="error" variant="body1">
          {error}
        </Typography>
      )}

      <Button variant="contained" color="error" onClick={() => handleOpenDialog()} style={{ marginBottom: '16px' }}>
        Ajouter un revendeur
      </Button>

      <Box>
        {retailers.length > 0 ? (
          retailers.map((retailer) => (
            <Paper key={retailer._id} sx={{ p: 2, mb: 2, display: 'flex', alignItems: 'center' }}>
              <Box sx={{ mr: 2 }}>
                <img src={retailer.logoUrl} alt={retailer.name} style={{ width: '100px', height: 'auto' }} />
              </Box>
              <Box sx={{ flexGrow: 1 }}>
                <Typography variant="h6">{retailer.name}</Typography>
              </Box>
              <Box>
                <Button
                  onClick={() => handleOpenDialog(retailer)}
                  variant="outlined"
                  sx={{
                    mr: 1,
                    borderColor: '#1976d2',
                    color: '#1976d2',
                    '&:hover': { backgroundColor: '#1976d2', color: '#fff' },
                  }}
                >
                  Modifier
                </Button>
                <Button
                  color="error"
                  onClick={() => handleDeleteRetailer(retailer._id)}
                  variant="outlined"
                  sx={{
                    borderColor: '#d32f2f',
                    color: '#d32f2f',
                    '&:hover': { backgroundColor: '#d32f2f', color: '#fff' },
                  }}
                >
                  Supprimer
                </Button>
              </Box>
            </Paper>
          ))
        ) : (
          !error && (
            <Typography variant="body1" color="textSecondary">
              Aucun revendeur trouvé.
            </Typography>
          )
        )}
      </Box>

      <Dialog open={openDialog} onClose={handleCloseDialog}>
        <DialogTitle>{editRetailer ? 'Modifier le Revendeur' : 'Ajouter un Revendeur'}</DialogTitle>
        <DialogContent>
          <TextField
            fullWidth
            margin="normal"
            label="Nom"
            value={editRetailer?.name || ''}
            onChange={(e) => setEditRetailer({ ...editRetailer, name: e.target.value })}
          />
          <TextField
            fullWidth
            margin="normal"
            label="URL du logo"
            value={editRetailer?.logoUrl || ''}
            onChange={(e) => setEditRetailer({ ...editRetailer, logoUrl: e.target.value })}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="secondary">
            Annuler
          </Button>
          <Button onClick={handleSaveRetailer} variant="contained" color="primary">
            Enregistrer
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default AdminRetailerManagement;
