import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Container, Typography, Box, Button } from '@mui/material';

function AccountActivation() {
  const API_URL = process.env.REACT_APP_API_URL; // Ajout de la variable d'environnement pour l'URL de l'API
  const { token } = useParams();
  const navigate = useNavigate();
  const [message, setMessage] = useState("");
  const [error, setError] = useState(false);
  const [activationAttempted, setActivationAttempted] = useState(false);

  useEffect(() => {
    const activateAccount = async () => {
      if (!activationAttempted) {
        try {
          // Utilisation de l'URL dynamique depuis la variable d'environnement
          const response = await fetch(`${API_URL}/users/activate/${token}`);
          const data = await response.json();

          if (response.ok) {
            setMessage(data.message);
            setError(false);
          } else {
            setError(true);
            setMessage(data.message);
          }
        } catch (err) {
          console.error("Erreur lors de la tentative d'activation :", err);
          setError(true);
          setMessage("Une erreur est survenue lors de l'activation du compte.");
        } finally {
          setActivationAttempted(true);
        }
      }
    };

    activateAccount();
  }, [API_URL, token, activationAttempted]);

  const handleGoToLogin = () => {
    navigate('/login');
  };

  return (
    <Container maxWidth="sm">
      <Box sx={{ mt: 5, mb: 3, textAlign: 'center' }}>
        <Typography variant="h4" component="h1" gutterBottom>
          Activation du Compte
        </Typography>
        <Typography variant="body1" color={error ? "error" : "success"} sx={{ mb: 3 }}>
          {message}
        </Typography>
        {!error && (
          <Button
            variant="contained"
            color="primary"
            onClick={handleGoToLogin}
          >
            Aller à la page de connexion
          </Button>
        )}
      </Box>
    </Container>
  );
}

export default AccountActivation;
