// src/components/registrationpending/RegistrationPending.js
import React from 'react';
import { Container, Box, Typography, Button, Link } from '@mui/material';

function RegistrationPending() {
  return (
    <Container maxWidth="sm">
      <Box sx={{ mt: 5, mb: 3, textAlign: 'center' }}>
        <Typography variant="h4" component="h1" gutterBottom>
          Merci pour votre inscription !
        </Typography>
        <Typography variant="body1" gutterBottom>
          Un email de confirmation vous a été envoyé. Veuillez vérifier votre boîte de réception pour activer votre compte en cliquant sur le lien fourni.
        </Typography>
        <Typography variant="body2" color="textSecondary" sx={{ mb: 3 }}>
          Assurez-vous de vérifier votre dossier de courrier indésirable si vous ne voyez pas l'email de confirmation.
        </Typography>
        <Link href="/resend-activation" underline="hover" color="primary">
          Vous n'avez pas reçu l'email ? Renvoyer le lien d'activation
        </Link>
        <Button 
          variant="contained" 
          color="primary" 
          href="/" 
          sx={{ mt: 3, padding: '10px 20px', fontWeight: 'bold', textTransform: 'none' }}
        >
          Retour à la page d'accueil
        </Button>
      </Box>
    </Container>
  );
}

export default RegistrationPending;
