// src/components/admin/AdminUserManagement.js
import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import {
  Container,
  Typography,
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  DialogActions,
  MenuItem,
  TablePagination,
} from '@mui/material';
import { Edit, PersonAdd } from '@mui/icons-material';

const AdminUserManagement = () => {
  const [users, setUsers] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    role: 'user',
    password: '',
  });
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const API_URL = process.env.REACT_APP_API_URL; 


  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const token = localStorage.getItem('adminToken');
        const response = await fetch(`${API_URL}/admin/users`, {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        });

        if (response.ok) {
          const data = await response.json();
          setUsers(data);
        } else {
          console.error('Erreur lors de la récupération des utilisateurs:', response.statusText);
        }
      } catch (error) {
        console.error('Erreur lors du chargement des utilisateurs:', error);
      }
    };

    fetchUsers();
  }, []);

  const handleOpenDialog = (user) => {
    setSelectedUser(user);
    setFormData(user ? { ...user, password: '' } : { firstName: '', lastName: '', email: '', role: 'user', password: '' });
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setSelectedUser(null);
    setOpenDialog(false);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSaveUser = async () => {
    try {
      const token = localStorage.getItem('adminToken');
      if (selectedUser) {
        const response = await fetch(`${API_URL}/admin/user/${selectedUser._id}`, {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            firstName: formData.firstName,
            lastName: formData.lastName,
            email: formData.email,
            role: formData.role,
          }),
        });
        if (response.ok) {
          setUsers(users.map((user) => (user._id === selectedUser._id ? { ...formData, _id: user._id } : user)));
        } else {
          console.error('Erreur lors de la mise à jour de l’utilisateur');
        }
      } else {
        const response = await fetch(`${API_URL}/admin/users`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(formData),
        });
        if (response.ok) {
          const newUser = await response.json();
          setUsers([...users, newUser]);
        } else {
          console.error('Erreur lors de l’ajout de l’utilisateur');
        }
      }
      handleCloseDialog();
    } catch (error) {
      console.error('Erreur lors de la sauvegarde de l’utilisateur:', error);
    }
  };

  const handleSuspendUser = async (userId) => {
    try {
      const response = await fetch(`${API_URL}/admin/user/${userId}/status`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('adminToken')}`,
        },
        body: JSON.stringify({ status: 'suspendu' }),
      });
      if (response.ok) {
        setUsers(users.map((user) => (user._id === userId ? { ...user, status: 'suspendu' } : user)));
      } else {
        console.error('Erreur lors de la suspension de l’utilisateur:', response.statusText);
      }
    } catch (error) {
      console.error('Erreur lors de la suspension de l’utilisateur:', error);
    }
  };

  const handleReactivateUser = async (userId) => {
    try {
      const response = await fetch(`${API_URL}/admin/user/${userId}/status`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('adminToken')}`,
        },
        body: JSON.stringify({ status: 'actif' }),
      });
      if (response.ok) {
        setUsers(users.map((user) => (user._id === userId ? { ...user, status: 'actif' } : user)));
      } else {
        console.error('Erreur lors de la réactivation de l’utilisateur:', response.statusText);
      }
    } catch (error) {
      console.error('Erreur lors de la réactivation de l’utilisateur:', error);
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const generateTemporaryPassword = () => {
    const randomPassword = Math.random().toString(36).slice(-8);
    setFormData((prevData) => ({
      ...prevData,
      password: randomPassword,
    }));
  };

  return (
    <Box sx={{ display: 'flex' }}>
      <Helmet>
        <title>Gestion des Utilisateurs - Admin</title>
        <meta name="description" content="Page de gestion des utilisateurs pour l'administrateur. Ajouter, modifier, suspendre ou réactiver des utilisateurs." />
        <meta name="keywords" content="admin, gestion des utilisateurs, ajouter utilisateur, modifier utilisateur, suspendre utilisateur, réactiver utilisateur" />
      </Helmet>
      <Container>
        <Box my={4}>
          <Typography variant="h4" component="h1" gutterBottom>
            Gestion des Utilisateurs
          </Typography>
          <Button
            variant="contained"
            color="primary"
            startIcon={<PersonAdd />}
            sx={{ mb: 2 }}
            onClick={() => handleOpenDialog(null)}
          >
            Ajouter un utilisateur
          </Button>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>ID</TableCell>
                  <TableCell>Prénom</TableCell>
                  <TableCell>Nom</TableCell>
                  <TableCell>Email</TableCell>
                  <TableCell>Rôle</TableCell>
                  <TableCell>Statut</TableCell>
                  <TableCell>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {users.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((user) => (
                  <TableRow key={user._id}>
                    <TableCell>{user._id}</TableCell>
                    <TableCell>{user.firstName}</TableCell>
                    <TableCell>{user.lastName}</TableCell>
                    <TableCell>{user.email}</TableCell>
                    <TableCell>{user.role}</TableCell>
                    <TableCell>{user.status}</TableCell>
                    <TableCell>
                      <IconButton color="primary" onClick={() => handleOpenDialog(user)}>
                        <Edit />
                      </IconButton>
                      {user.status === 'actif' ? (
                        <Button color="warning" onClick={() => handleSuspendUser(user._id)}>
                          Suspendre
                        </Button>
                      ) : (
                        <Button color="success" onClick={() => handleReactivateUser(user._id)}>
                          Réactiver
                        </Button>
                      )}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={users.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Box>
      </Container>

      <Dialog open={openDialog} onClose={handleCloseDialog}>
        <DialogTitle>{selectedUser ? 'Modifier Utilisateur' : 'Ajouter un Utilisateur'}</DialogTitle>
        <DialogContent>
          <TextField
            margin="dense"
            label="Prénom"
            name="firstName"
            fullWidth
            variant="outlined"
            value={formData.firstName}
            onChange={handleChange}
          />
          <TextField
            margin="dense"
            label="Nom"
            name="lastName"
            fullWidth
            variant="outlined"
            value={formData.lastName}
            onChange={handleChange}
          />
          <TextField
            margin="dense"
            label="Email"
            name="email"
            fullWidth
            variant="outlined"
            value={formData.email}
            onChange={handleChange}
          />
          {!selectedUser && (
            <>
              <TextField
                margin="dense"
                label="Mot de passe"
                name="password"
                fullWidth
                variant="outlined"
                value={formData.password}
                onChange={handleChange}
              />
              <Button variant="contained" color="primary" onClick={generateTemporaryPassword} sx={{ mt: 2 }}>
                Générer un mot de passe temporaire
              </Button>
            </>
          )}
          <TextField
            margin="dense"
            label="Rôle"
            name="role"
            select
            fullWidth
            variant="outlined"
            value={formData.role}
            onChange={handleChange}
          >
            <MenuItem value="user">Utilisateur</MenuItem>
            <MenuItem value="admin">Administrateur</MenuItem>
          </TextField>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog}>Annuler</Button>
          <Button onClick={handleSaveUser} variant="contained" color="primary">
            Sauvegarder
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default AdminUserManagement;
