import React, { useEffect, useState, useRef } from 'react';
import {
  Box,
  Typography,
  Container,
  Button,
  Paper,
  Divider,
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  CircularProgress,
  RadioGroup,
  FormControlLabel,
  Radio,
  Snackbar,
  useTheme,
  useMediaQuery,
  Alert,
} from '@mui/material';
import { Helmet } from 'react-helmet';
import { useParams, useNavigate } from 'react-router-dom';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import InsertDriveFileOutlinedIcon from '@mui/icons-material/InsertDriveFileOutlined';
import ShieldOutlinedIcon from '@mui/icons-material/ShieldOutlined';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { StandaloneSearchBox, useJsApiLoader } from '@react-google-maps/api';
import { GlobalStyles } from '@mui/system';

const libraries = ['places'];

function WarrantyDetail() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [warranty, setWarranty] = useState(null);
  const [error, setError] = useState('');
  const [openAddressDialog, setOpenAddressDialog] = useState(false);
  const [selectedAddress, setSelectedAddress] = useState(null);
  const [addressType, setAddressType] = useState('particulier');
  const [companyName, setCompanyName] = useState('');
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: '' });
  const [isGeneratingCertificate, setIsGeneratingCertificate] = useState(false);
  const [openCertificateDialog, setOpenCertificateDialog] = useState(false);
  const inputRef = useRef();
  const [searchBox, setSearchBox] = useState(null);
  const API_URL = process.env.REACT_APP_API_URL;

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
    libraries,
  });

  const handleCloseSnackbar = () => setSnackbar({ ...snackbar, open: false });

  useEffect(() => {
    const fetchWarranty = async () => {
      try {
        const token = localStorage.getItem('adminToken') || localStorage.getItem('token');
        const response = await fetch(`${API_URL}/warranty/${id}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        if (!response.ok) {
          throw new Error("Erreur lors de la récupération des détails de l'outil");
        }
        const data = await response.json();
        setWarranty(data);
      } catch (err) {
        setError(err.message);
      }
    };

    const fetchUserProfile = async () => {
      try {
        const token = localStorage.getItem('adminToken') || localStorage.getItem('token');
        const response = await fetch(`${API_URL}/users/profile`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        if (!response.ok) {
          throw new Error("Erreur lors de la récupération du profil utilisateur.");
        }
        const userData = await response.json();
        if (userData.address && userData.address.addressLine) {
          setSelectedAddress(userData.address);
        } else {
          setSelectedAddress(null);
        }
      } catch (err) {
        console.error(err.message);
        setSnackbar({
          open: true,
          message: "Impossible de récupérer les informations de l'utilisateur.",
          severity: 'error',
        });
      }
    };

    fetchWarranty();
    fetchUserProfile();
  }, [id]);

  const onLoad = (ref) => {
    setSearchBox(ref);
  };

  const onPlacesChanged = () => {
    if (searchBox) {
      const places = searchBox.getPlaces();
      if (places && places.length > 0) {
        const place = places[0];

        let address = '';
        let postalCode = '';
        let city = '';
        let country = '';

        place.address_components.forEach((component) => {
          const componentType = component.types[0];
          switch (componentType) {
            case 'street_number':
              address = `${component.long_name} ${address}`;
              break;
            case 'route':
              address += component.long_name;
              break;
            case 'locality':
              city = component.long_name;
              break;
            case 'postal_code':
              postalCode = component.long_name;
              break;
            case 'country':
              country = component.long_name;
              break;
            default:
              break;
          }
        });

        setSelectedAddress({
          addressLine: address.trim(),
          postalCode,
          city,
          country,
        });
      }
    }
  };

  const saveAddress = async () => {
    try {
      const addressData = { ...selectedAddress, type: addressType };
      if (addressType === 'professionnel') {
        addressData.companyName = companyName;
      }
      const token = localStorage.getItem('token');
      const response = await fetch(`${API_URL}/users/update-address`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(addressData),
      });

      if (!response.ok) {
        throw new Error("Erreur lors de la mise à jour de l'adresse.");
      }

      const updatedData = await response.json();
      setSelectedAddress(updatedData.address);
      setOpenAddressDialog(false);

      // Ouvrir la boîte de dialogue de génération du certificat
      setOpenCertificateDialog(true);
      setIsGeneratingCertificate(true);

      // Attendre 3 secondes
      await new Promise((resolve) => setTimeout(resolve, 3000));

      setIsGeneratingCertificate(false);
      setOpenCertificateDialog(false);

      setSnackbar({
        open: true,
        message: 'Votre certificat est prêt.',
        severity: 'success',
      });

      window.open(`${API_URL}/warranty/certificate/${id}`, '_blank');
    } catch (err) {
      console.error(err);
      setSnackbar({
        open: true,
        message: "Erreur lors de la sauvegarde de l'adresse.",
        severity: 'error',
      });
    }
  };

  const handleDownloadCertificate = async () => {
    if (!selectedAddress || !selectedAddress.addressLine) {
      setOpenAddressDialog(true);
      setSnackbar({
        open: true,
        message: "Vous devez renseigner une adresse avant de télécharger le certificat.",
        severity: 'warning',
      });
      return;
    }

    // Ouvrir la boîte de dialogue
    setOpenCertificateDialog(true);
    setIsGeneratingCertificate(true);

    // Attendre 3 secondes
    await new Promise((resolve) => setTimeout(resolve, 3000));

    setIsGeneratingCertificate(false);
    // Fermer la boîte de dialogue
    setOpenCertificateDialog(false);

    // Ouvrir le certificat dans un nouvel onglet
    window.open(`${API_URL}/warranty/certificate/${id}`, '_blank');
  };

  if (error) {
    return (
      <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
        <Typography variant="h6" color="error">
          {error}
        </Typography>
      </Container>
    );
  }

  if (!warranty) {
    return (
      <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
        <Typography variant="h6">Chargement des données...</Typography>
      </Container>
    );
  }

  const handleDownloadInvoice = () => {
    window.open(`${API_URL}/warranty/invoice/${id}`, '_blank');
  };

  const handleDownloadLabel = () => {
    window.open(`${API_URL}/warranty/label/${id}`, '_blank');
  };

  if (!isLoaded) {
    return <div>Chargement du script Google Maps...</div>;
  }

  return (
    <>
      <GlobalStyles styles={{ '.pac-container': { zIndex: 2000 } }} />
      <Container maxWidth="md" sx={{ py: { xs: 2, sm: 4 } }}>
        <Helmet>
          <title>Détails de l'Outil - myHanger</title>
          <meta
            name="description"
            content="Consultez les détails de l'outil enregistré avec votre garantie."
          />
        </Helmet>

        <Box sx={{ textAlign: 'left', mb: 2 }}>
          <Button
            variant="contained"
            color="primary"
            onClick={() => navigate(-1)}
            sx={{ borderRadius: '12px', fontWeight: 'bold' }}
          >
            Retour
          </Button>
        </Box>

        <Box sx={{ textAlign: 'center', mb: 4 }}>
          <img
            src={warranty.productId?.imageUrl || '/default-product-image.png'}
            alt={warranty.productId?.model || 'Produit'}
            style={{
              maxWidth: '200px',
              height: 'auto',
              borderRadius: 8,
              boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
            }}
          />
          <Typography variant={isMobile ? 'h5' : 'h4'} sx={{ mt: 2 }}>
            {warranty.productId?.model || 'Produit'}
          </Typography>
          <Typography variant="subtitle1" color="text.secondary">
            {warranty.productId?.category}
          </Typography>
        </Box>

        {/* Ajustement du Grid pour uniformiser les hauteurs et gérer la responsivité */}
        <Grid container spacing={4} sx={{ mb: 4 }}>
          <Grid item xs={12} md={6}>
            <Paper
              elevation={3}
              sx={{
                p: 3,
                borderRadius: 2,
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
              }}
            >
              <Box>
                <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                  <InfoOutlinedIcon color="primary" sx={{ mr: 1 }} />
                  <Typography variant="h6">Données Outil</Typography>
                </Box>
                <Divider sx={{ mb: 2 }} />
                <Typography variant="body1" sx={{ mb: 1 }}>
                  <strong>Numéro de garantie :</strong> {warranty.warrantyNumber || 'N/A'}
                </Typography>
                <Typography variant="body1" sx={{ mb: 1 }}>
                  <strong>Numéro de produit :</strong> {warranty.productId?.productNumber || 'N/A'}
                </Typography>
                <Typography variant="body1" sx={{ mb: 1 }}>
                  <strong>Numéro de série :</strong> {warranty.serialNumberId?.serialNumber || 'N/A'}
                </Typography>
                <Typography variant="body1" sx={{ mb: 1 }}>
                  <strong>Date d'achat :</strong>{' '}
                  {warranty.purchaseDate
                    ? new Date(warranty.purchaseDate).toLocaleDateString('fr-FR')
                    : 'Date invalide'}
                </Typography>
              </Box>
            </Paper>
          </Grid>

          <Snackbar
            open={snackbar.open}
            autoHideDuration={6000}
            onClose={handleCloseSnackbar}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          >
            <Alert
              onClose={handleCloseSnackbar}
              severity={snackbar.severity}
              elevation={6}
              variant="filled"
            >
              {snackbar.message}
            </Alert>
          </Snackbar>

          <Grid item xs={12} md={6}>
            <Paper
              elevation={3}
              sx={{
                p: 3,
                borderRadius: 2,
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
              }}
            >
              <Box>
                <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                  <ShieldOutlinedIcon color="primary" sx={{ mr: 1 }} />
                  <Typography variant="h6">Service</Typography>
                </Box>
                <Divider sx={{ mb: 2 }} />
                <Typography variant="body1" sx={{ fontWeight: 'bold', mb: 1 }}>
                  {warranty.productId?.warrantyPeriod
                    ? `${Math.ceil(warranty.productId.warrantyPeriod / 12)} Ans de Garantie`
                    : 'Durée de garantie inconnue'}
                </Typography>
                <Typography variant="body2" sx={{ mb: 2 }}>
                  Valable jusqu'au{' '}
                  {warranty.warrantyEndDate
                    ? new Date(warranty.warrantyEndDate).toLocaleDateString('fr-FR')
                    : 'Date invalide'}
                </Typography>
              </Box>
              <Box sx={{ textAlign: 'center', mt: 2 }}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleDownloadCertificate}
                  sx={{ borderRadius: '12px', fontWeight: 'bold', width: '100%' }}
                >
                  Télécharger le certificat
                </Button>
              </Box>
            </Paper>
          </Grid>
        </Grid>

        {/* Ajustement de la section "Annexes" */}
        <Paper
          elevation={3}
          sx={{
            p: 3,
            mt: 4,
            borderRadius: 2,
          }}
        >
          <Typography
            variant="h6"
            gutterBottom
            sx={{ display: 'flex', alignItems: 'center', gap: 1 }}
          >
            <DescriptionOutlinedIcon color="primary" />
            Annexes
          </Typography>
          <Divider sx={{ mb: 2 }} />
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <Paper
                elevation={2}
                sx={{
                  p: 3,
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  borderRadius: 2,
                  cursor: 'pointer',
                  height: '100%',
                  '&:hover': {
                    boxShadow: '0px 8px 16px rgba(0, 0, 0, 0.2)',
                  },
                }}
                onClick={handleDownloadInvoice}
                role="button"
              >
                <InsertDriveFileOutlinedIcon
                  sx={{ fontSize: 60, color: theme.palette.primary.main, mb: 2 }}
                />
                <Box sx={{ textAlign: 'center' }}>
                  <Typography variant="h6" sx={{ fontWeight: 'bold', mb: 1 }}>
                    Facture
                  </Typography>
                  <Typography
                    variant="body2"
                    color="text.secondary"
                    sx={{ mb: 2, textAlign: 'center' }}
                  >
                    Cliquez pour télécharger votre facture d'achat.
                  </Typography>
                </Box>
              </Paper>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Paper
                elevation={2}
                sx={{
                  p: 3,
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  borderRadius: 2,
                  cursor: 'pointer',
                  height: '100%',
                  '&:hover': {
                    boxShadow: '0px 8px 16px rgba(0, 0, 0, 0.2)',
                  },
                }}
                onClick={handleDownloadLabel}
                role="button"
              >
                <InsertDriveFileOutlinedIcon
                  sx={{ fontSize: 60, color: theme.palette.primary.main, mb: 2 }}
                />
                <Box sx={{ textAlign: 'center' }}>
                  <Typography variant="h6" sx={{ fontWeight: 'bold', mb: 1 }}>
                    Étiquette signalétique
                  </Typography>
                  <Typography
                    variant="body2"
                    color="text.secondary"
                    sx={{ mb: 2, textAlign: 'center' }}
                  >
                    Cliquez pour télécharger l'étiquette de votre produit.
                  </Typography>
                </Box>
              </Paper>
            </Grid>
          </Grid>
        </Paper>

        {/* Dialogue pour la mise à jour de l'adresse */}
        <Dialog open={openAddressDialog} onClose={() => setOpenAddressDialog(false)}>
          <DialogTitle>
            {selectedAddress ? "Modifier l'adresse" : 'Ajouter une adresse'}
          </DialogTitle>
          <DialogContent>
            <RadioGroup
              value={addressType}
              onChange={(event) => setAddressType(event.target.value)}
              row
              sx={{ marginBottom: 2 }}
            >
              <FormControlLabel value="particulier" control={<Radio />} label="Domicile" />
              <FormControlLabel value="professionnel" control={<Radio />} label="Professionnel" />
            </RadioGroup>
            {addressType === 'professionnel' && (
              <TextField
                fullWidth
                label="Nom de l'entreprise"
                value={companyName}
                onChange={(e) => setCompanyName(e.target.value)}
                margin="normal"
                sx={{ borderRadius: '12px' }}
              />
            )}
            {!selectedAddress ? (
              <StandaloneSearchBox onLoad={onLoad} onPlacesChanged={onPlacesChanged}>
                <TextField
                  fullWidth
                  placeholder="Rechercher une adresse"
                  inputRef={inputRef}
                  margin="normal"
                  sx={{ borderRadius: '12px' }}
                />
              </StandaloneSearchBox>
            ) : (
              <>
                <TextField
                  fullWidth
                  label="Adresse"
                  value={selectedAddress.addressLine}
                  onChange={(e) =>
                    setSelectedAddress({ ...selectedAddress, addressLine: e.target.value })
                  }
                  margin="normal"
                  sx={{ borderRadius: '12px' }}
                />
                <TextField
                  fullWidth
                  label="Code Postal"
                  value={selectedAddress.postalCode}
                  onChange={(e) =>
                    setSelectedAddress({ ...selectedAddress, postalCode: e.target.value })
                  }
                  margin="normal"
                  sx={{ borderRadius: '12px' }}
                />
                <TextField
                  fullWidth
                  label="Ville"
                  value={selectedAddress.city}
                  onChange={(e) =>
                    setSelectedAddress({ ...selectedAddress, city: e.target.value })
                  }
                  margin="normal"
                  sx={{ borderRadius: '12px' }}
                />
                <TextField
                  fullWidth
                  label="Pays"
                  value={selectedAddress.country}
                  onChange={(e) =>
                    setSelectedAddress({ ...selectedAddress, country: e.target.value })
                  }
                  margin="normal"
                  sx={{ borderRadius: '12px' }}
                />
              </>
            )}
          </DialogContent>
          <DialogActions>
            {selectedAddress ? (
              <>
                <Button onClick={() => setSelectedAddress(null)}>Rechercher à nouveau</Button>
                <Button
                  onClick={saveAddress}
                  color="primary"
                  variant="contained"
                  sx={{ borderRadius: '12px', fontWeight: 'bold' }}
                >
                  Enregistrer
                </Button>
              </>
            ) : (
              <Button onClick={() => setOpenAddressDialog(false)}>Annuler</Button>
            )}
          </DialogActions>
        </Dialog>

        {/* Dialogue pour la génération du certificat */}
        <Dialog
          open={openCertificateDialog}
          onClose={() => setOpenCertificateDialog(false)}
          aria-labelledby="certificate-dialog-title"
        >
          <DialogTitle id="certificate-dialog-title">Génération du certificat</DialogTitle>
          <DialogContent sx={{ textAlign: 'center', p: 4 }}>
            {isGeneratingCertificate ? (
              <>
                <CircularProgress sx={{ mb: 2 }} />
                <Typography>
                  Votre certificat est en cours de génération, veuillez patienter...
                </Typography>
              </>
            ) : (
              <Typography>Votre certificat est prêt !</Typography>
            )}
          </DialogContent>
          {!isGeneratingCertificate && (
            <DialogActions>
              <Button onClick={() => setOpenCertificateDialog(false)}>Fermer</Button>
            </DialogActions>
          )}
        </Dialog>
      </Container>
    </>
  );
}

export default WarrantyDetail;
