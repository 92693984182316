// src/components/privacy/Privacy.js
import React from 'react';
import { Container, Box, Typography } from '@mui/material';

function Privacy() {
  return (
    <Container maxWidth="lg">
      <Box sx={{ mt: 5 }}>
        <Typography variant="h4" gutterBottom>
          Politique de confidentialité
        </Typography>
        <Typography variant="body1" paragraph>
          Chez Hanger, nous accordons une grande importance à la protection de votre vie privée. Cette politique de confidentialité décrit les types d'informations que nous collectons, la manière dont nous les utilisons et les mesures que nous prenons pour les protéger.
        </Typography>
        <Typography variant="body1" paragraph>
          <strong>1. Collecte des informations</strong><br />
          Nous collectons des informations personnelles que vous nous fournissez directement, telles que votre nom, votre adresse e-mail et vos informations de contact lorsque vous créez un compte ou nous contactez. Nous recueillons également des informations automatiquement, comme votre adresse IP et votre comportement de navigation sur notre site.
        </Typography>
        <Typography variant="body1" paragraph>
          <strong>2. Utilisation des informations</strong><br />
          Les informations collectées sont utilisées pour améliorer nos services, personnaliser votre expérience utilisateur, traiter vos commandes et vous fournir des informations sur nos produits et services.
        </Typography>
        <Typography variant="body1" paragraph>
          <strong>3. Partage des informations</strong><br />
          Nous ne vendons ni ne louons vos informations personnelles à des tiers. Nous pouvons partager vos informations avec des partenaires de confiance qui nous aident à exploiter notre site web, à mener nos activités ou à vous servir, tant que ces partenaires s'engagent à garder ces informations confidentielles.
        </Typography>
        <Typography variant="body1" paragraph>
          <strong>4. Sécurité des données</strong><br />
          Nous mettons en place des mesures de sécurité appropriées pour protéger vos informations personnelles contre tout accès non autorisé, altération, divulgation ou destruction.
        </Typography>
        <Typography variant="body1" paragraph>
          <strong>5. Cookies</strong><br />
          Notre site utilise des cookies pour améliorer votre expérience de navigation. Vous pouvez choisir de désactiver les cookies via les paramètres de votre navigateur, mais cela pourrait affecter certaines fonctionnalités du site.
        </Typography>
        <Typography variant="body1" paragraph>
          <strong>6. Vos droits</strong><br />
          Vous avez le droit d'accéder à vos informations personnelles, de les corriger, de demander leur suppression ou de limiter leur traitement. Pour exercer ces droits, veuillez nous contacter à <a href="mailto:contact@hangertools.com">contact@hangertools.com</a>.
        </Typography>
        <Typography variant="body1" paragraph>
          <strong>7. Modifications de la politique</strong><br />
          Cette politique de confidentialité peut être mise à jour périodiquement. Toute modification sera publiée sur cette page.
        </Typography>
        <Typography variant="body1" paragraph>
          <strong>8. Contact</strong><br />
          Pour toute question concernant notre politique de confidentialité, veuillez nous contacter à l'adresse suivante : <a href="mailto:contact@hangertools.com">contact@hangertools.com</a>.
        </Typography>
      </Box>
    </Container>
  );
}

export default Privacy;
