import React, { useState } from 'react';
import { TextField, Button, Container, Box, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';

function AdminLogin() {
  const [formData, setFormData] = useState({ email: '', password: '' });
  const navigate = useNavigate();

  // Utiliser une variable d'environnement pour l'URL de l'API
  const API_URL = process.env.REACT_APP_API_URL;

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async () => {
    try {
      const response = await fetch(`${API_URL}/admin/login`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });
      const data = await response.json();
      if (response.ok) {
        console.log('Token reçu:', data.token);
        localStorage.setItem('adminToken', data.token);
        console.log('Admin logged in successfully, navigating to /admin/dashboard');
        navigate('/admin/dashboard'); // Rediriger vers le dashboard admin
      } else {
        console.error('Erreur de connexion :', data.message);
      }
    } catch (error) {
      console.error('Erreur réseau :', error);
    }
  };

  return (
    <Container maxWidth="sm">
      <Box sx={{ mt: 5, mb: 3, textAlign: 'center' }}>
        <Typography variant="h4" component="h1" gutterBottom>
          Connexion Admin
        </Typography>
        <TextField
          fullWidth
          name="email"
          label="Adresse Email"
          variant="outlined"
          margin="normal"
          value={formData.email}
          onChange={handleChange}
        />
        <TextField
          fullWidth
          name="password"
          label="Mot de Passe"
          type="password"
          variant="outlined"
          margin="normal"
          value={formData.password}
          onChange={handleChange}
        />
        <Button
          variant="contained"
          color="primary"
          sx={{ mt: 3 }}
          onClick={handleSubmit}
        >
          Se connecter
        </Button>
      </Box>
    </Container>
  );
}

export default AdminLogin;
