// src/app.js
import React from 'react';
import { Helmet } from 'react-helmet';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Header from './components/Header';
import Footer from './components/Footer';
import WarrantyRegistrationSteps from './components/warrantyregistrationsteps/warrantyregistrationsteps';
import Login from './components/login/login';
import Register from './components/register/register';
import RegistrationPending from './components/registrationpending/registrationpending';
import UserWarranties from './components/userwarranties/userwarranties';
import WarrantyDetail from './components/warrantydetail/WarrantyDetail';
import PasswordRecovery from './components/passwordrecovery/passwordrecovery';
import Terms from './components/terms/Terms';
import Privacy from './components/privacy/Privacy';
import AdminDashboard from './components/admin/AdminDashboard';
import AdminUserManagement from './components/admin/AdminUserManagement';
import AdminWarrantyManagement from './components/admin/AdminWarrantyManagement';
import Settings from './components/admin/Settings';
import AdminLayout from './components/admin/AdminLayout';
import PrivateRoute from './components/PrivateRoute';
import AdminLogin from './components/admin/AdminLogin';
import AdminProductManagement from './components/admin/AdminProductManagement';
import AdminSerialNumberManagement from './components/admin/AdminSerialNumberManagement';
import AdminWarrantyDetail from './components/admin/AdminWarrantyDetail';
import AdminRetailerManagement from './components/admin/AdminRetailerManagement';
import AccountActivation from './components/AccountActivation/AccountActivation';
import ResendActivation from './components/resendactivation/ResendActivation';
import ResetPassword from './components/resetpassword/ResetPassword';
import UserProfile from './components/profile/Profile';
import AdminComboManagement from './components/admin/AdminComboManagement';


function App() {
  return (
    <Router>
      <Helmet>
        <title>Accueil - myHanger</title>
        <meta
          name="description"
          content="Bienvenue sur myHanger, la plateforme d'enregistrement des outils Hanger. Enregistrez vos outils et profitez de nos services."
        />
      </Helmet>
      <Header />
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/login" element={<Login />} />
        <Route path="/register" element={<Register />} />
        <Route path="/register-warranty" element={<WarrantyRegistrationSteps />} />
        <Route path="/register-confirmation" element={<RegistrationPending />} />
        <Route path="/user-warranties" element={<UserWarranties />} />
        <Route path="/forgot-password" element={<PasswordRecovery />} />
        <Route path="/terms" element={<Terms />} />
        <Route path="/privacy" element={<Privacy />} />
        <Route path="/warranty/:id" element={<WarrantyDetail />} />
        <Route path="/admin" element={<AdminLogin />} />
        <Route path="/activate/:token" element={<AccountActivation />} />
        <Route path="/resend-activation" element={<ResendActivation />} />
        <Route path="/reset-password/:token" element={<ResetPassword />} />
        <Route path="/profile" element={<UserProfile />} />



        {/* Back-office routes using AdminLayout */}
        <Route
          path="/admin/*"
          element={
            <PrivateRoute>
              <AdminLayout>
                <Routes>
                  <Route path="dashboard" element={<AdminDashboard />} />
                  <Route path="users" element={<AdminUserManagement />} />
                  <Route path="warranties" element={<AdminWarrantyManagement />} />
                  <Route path="settings" element={<Settings />} />
                  <Route path="products" element={<AdminProductManagement/>} />
                  <Route path="combos" element={<AdminComboManagement/>} />
                  <Route path="serial-numbers" element={<AdminSerialNumberManagement/>} />
                  <Route path="warranty/:id" element={<AdminWarrantyDetail />} />
                  <Route path="retailers" element={<AdminRetailerManagement />} />
                </Routes>
              </AdminLayout>
            </PrivateRoute>
          }
        />
      </Routes>
      <Footer />
    </Router>
  );
}

export default App;
