import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import {
  Container,
  Box,
  Typography,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TextField,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  IconButton,
  TablePagination,
  useTheme,
  useMediaQuery,
  InputAdornment,
  Card,
  CardContent,
  CardActions,
} from '@mui/material';
import { format } from 'date-fns';
import { Link } from 'react-router-dom';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import ShieldOutlinedIcon from '@mui/icons-material/ShieldOutlined';
import SearchIcon from '@mui/icons-material/Search';

function UserWarranties() {
  const [warranties, setWarranties] = useState([]);
  const [filteredWarranties, setFilteredWarranties] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [categoryFilter, setCategoryFilter] = useState('');
  const [statusFilter, setStatusFilter] = useState('');
  const [error, setError] = useState('');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const API_URL = process.env.REACT_APP_API_URL;

  useEffect(() => {
    const fetchWarranties = async () => {
      try {
        const token = localStorage.getItem('token');
        const response = await fetch(`${API_URL}/warranty/user`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        if (!response.ok) {
          throw new Error('Erreur lors de la récupération des garanties');
        }
        const data = await response.json();
        data.sort((a, b) => new Date(b.warrantyStartDate) - new Date(a.warrantyStartDate));
        setWarranties(data);
        setFilteredWarranties(data);
      } catch (err) {
        setError(err.message);
      }
    };
    fetchWarranties();
  }, [API_URL]);

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
    applyFilters(e.target.value, categoryFilter, statusFilter);
  };

  const handleCategoryFilterChange = (e) => {
    setCategoryFilter(e.target.value);
    applyFilters(searchTerm, e.target.value, statusFilter);
  };

  const handleStatusFilterChange = (e) => {
    setStatusFilter(e.target.value);
    applyFilters(searchTerm, categoryFilter, e.target.value);
  };

  const applyFilters = (search, category, status) => {
    let filtered = warranties;
    if (search) {
      filtered = filtered.filter((warranty) =>
        (warranty.productName && warranty.productName.toLowerCase().includes(search.toLowerCase())) ||
        (warranty.productRef && warranty.productRef.toLowerCase().includes(search.toLowerCase())) ||
        (warranty.serialNumber && warranty.serialNumber.toLowerCase().includes(search.toLowerCase()))
      );
    }
    if (category) {
      filtered = filtered.filter((warranty) => warranty.category === category);
    }
    if (status) {
      filtered = filtered.filter((warranty) =>
        status === 'active' ? warranty.status === 'active' : warranty.status === 'expired'
      );
    }
    setFilteredWarranties(filtered);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // Calculer les données à afficher en fonction de la pagination
  const displayedWarranties = filteredWarranties.slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage
  );

  return (
    <Container maxWidth="lg" sx={{ py: { xs: 2, sm: 4 } }}>
      <Helmet>
        <title>myHanger - Mes Outils Enregistrés</title>
        <meta
          name="description"
          content="Visualisez et gérez tous vos outils enregistrés sur myHanger."
        />
        <meta name="keywords" content="myHanger, outils enregistrés, gestion garantie, Hanger" />
      </Helmet>

      {/* Titre et bouton "Créer un Outil" */}
      <Box
        sx={{
          display: 'flex',
          flexDirection: { xs: 'column', sm: 'row' },
          justifyContent: 'space-between',
          alignItems: 'center',
          mt: { xs: 2, sm: 5 },
          mb: { xs: 2, sm: 3 },
          gap: 2,
        }}
      >
        <Typography variant={isMobile ? 'h5' : 'h4'} component="h1" sx={{ fontWeight: 700 }}>
          Mes Outils
        </Typography>
        {warranties.length > 0 && (
          <Button
            variant="contained"
            color="primary"
            component={Link}
            to="/register-warranty"
            sx={{
              py: 1,
              px: 3,
              borderRadius: '12px',
              textTransform: 'none',
              fontWeight: 'bold',
            }}
          >
            Créer un Outil
          </Button>
        )}
      </Box>

      {error && (
        <Typography variant="body2" color="error" gutterBottom>
          {error}
        </Typography>
      )}

      {/* Affichage alternatif si la liste est vide */}
      {warranties.length === 0 ? (
        <Box sx={{ textAlign: 'center', py: 5 }}>
          <Typography variant="h6" color="textSecondary" gutterBottom>
            Vous n'avez encore enregistré aucun outil.
          </Typography>
          <Typography variant="body1" color="textSecondary" sx={{ mb: 3 }}>
            Enregistrez vos outils pour bénéficier de garanties prolongées et d'un suivi simplifié
            de vos équipements.
          </Typography>
          <Button
            variant="contained"
            color="primary"
            size="large"
            component={Link}
            to="/register-warranty"
            sx={{
              py: 1.5,
              px: 4,
              borderRadius: '12px',
              textTransform: 'none',
              fontWeight: 'bold',
            }}
          >
            Créer un Outil
          </Button>
        </Box>
      ) : (
        <>
          {/* Recherche et filtres */}
          <Box
            sx={{
              display: 'flex',
              flexDirection: { xs: 'column', sm: 'row' },
              gap: 2,
              mb: 3,
            }}
          >
            <TextField
              label="Rechercher"
              variant="outlined"
              value={searchTerm}
              onChange={handleSearchChange}
              fullWidth
              sx={{
                '& .MuiOutlinedInput-root': {
                  borderRadius: '12px',
                },
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <SearchIcon color="action" />
                  </InputAdornment>
                ),
              }}
            />
            <FormControl
              variant="outlined"
              fullWidth
              sx={{
                minWidth: { xs: '100%', sm: 200 },
                '& .MuiOutlinedInput-root': {
                  borderRadius: '12px',
                },
              }}
            >
              <InputLabel>Catégorie</InputLabel>
              <Select
                value={categoryFilter}
                onChange={handleCategoryFilterChange}
                label="Catégorie"
              >
                <MenuItem value="">
                  <em>Toutes</em>
                </MenuItem>
                {[...new Set(warranties.map((warranty) => warranty.category))].map(
                  (category, index) => (
                    <MenuItem key={index} value={category}>
                      {category}
                    </MenuItem>
                  )
                )}
              </Select>
            </FormControl>
            <FormControl
              variant="outlined"
              fullWidth
              sx={{
                minWidth: { xs: '100%', sm: 200 },
                '& .MuiOutlinedInput-root': {
                  borderRadius: '12px',
                },
              }}
            >
              <InputLabel>Statut</InputLabel>
              <Select value={statusFilter} onChange={handleStatusFilterChange} label="Statut">
                <MenuItem value="">
                  <em>Tous</em>
                </MenuItem>
                <MenuItem value="active">Disponible</MenuItem>
                <MenuItem value="expired">Expirée</MenuItem>
              </Select>
            </FormControl>
          </Box>

          {/* Affichage du tableau sur les grands écrans et des cartes sur les petits écrans */}
          {!isMobile ? (
            <>
              {/* Tableau des garanties avec pagination */}
              <TableContainer component={Paper} sx={{ mt: 3 }}>
                <Table sx={{ minWidth: 650 }}>
                  <TableHead>
                    <TableRow>
                      <TableCell>Image</TableCell>
                      <TableCell>Outil</TableCell>
                      <TableCell>Catégorie</TableCell>
                      <TableCell>Référence Produit</TableCell>
                      <TableCell>Numéro de Série</TableCell>
                      <TableCell>Date d'Achat</TableCell>
                      <TableCell>Statut</TableCell>
                      <TableCell>Actions</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {displayedWarranties.map((warranty) => (
                      <TableRow key={warranty.warrantyNumber}>
                        <TableCell>
                          {warranty.imageUrl ? (
                            <img
                              src={warranty.imageUrl}
                              alt={warranty.productName}
                              style={{ width: '80px', height: 'auto' }}
                            />
                          ) : (
                            'Pas d\'image'
                          )}
                        </TableCell>
                        <TableCell>{warranty.productName || 'N/A'}</TableCell>
                        <TableCell>{warranty.category || 'N/A'}</TableCell>
                        <TableCell>{warranty.productRef || '-'}</TableCell>
                        <TableCell>{warranty.serialNumber || '-'}</TableCell>
                        <TableCell>
                          {warranty.purchaseDate && !isNaN(new Date(warranty.purchaseDate))
                            ? format(new Date(warranty.purchaseDate), 'dd/MM/yyyy')
                            : 'Date invalide'}
                        </TableCell>
                        <TableCell>
                          <ShieldOutlinedIcon
                            sx={{
                              color: warranty.status === 'active' ? 'green' : 'lightgrey',
                              fontSize: 30,
                            }}
                          />
                        </TableCell>
                        <TableCell>
                          <IconButton
                            component={Link}
                            to={`/warranty/${warranty.warrantyNumber}`}
                            color="primary"
                          >
                            <InfoOutlinedIcon />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>

              {/* Pagination */}
              <TablePagination
                component="div"
                count={filteredWarranties.length}
                page={page}
                onPageChange={handleChangePage}
                rowsPerPage={rowsPerPage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                rowsPerPageOptions={[5, 10, 25]}
                sx={{
                  mt: 2,
                  '& .MuiTablePagination-selectLabel, & .MuiTablePagination-displayedRows': {
                    marginTop: 'auto',
                    marginBottom: 'auto',
                  },
                }}
              />
            </>
          ) : (
            <>
              {/* Affichage des cartes sur les petits écrans */}
              <Box sx={{ mt: 2 }}>
                {displayedWarranties.map((warranty) => (
                  <Card
                    key={warranty.warrantyNumber}
                    sx={{
                      mb: 2,
                      borderRadius: '12px',
                      boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.05)',
                    }}
                  >
                    <CardContent>
                      <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                        <Box sx={{ mr: 2 }}>
                          {warranty.imageUrl ? (
                            <img
                              src={warranty.imageUrl}
                              alt={warranty.productName}
                              style={{ width: '80px', height: 'auto' }}
                            />
                          ) : (
                            <Box
                              sx={{
                                width: 80,
                                height: 80,
                                backgroundColor: '#f0f0f0',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                              }}
                            >
                              Pas d'image
                            </Box>
                          )}
                        </Box>
                        <Box sx={{ flexGrow: 1 }}>
                          <Typography variant="h6" sx={{ fontWeight: 600 }}>
                            {warranty.productName || 'N/A'}
                          </Typography>
                          <Typography variant="body2" color="text.secondary">
                            {warranty.category || 'N/A'}
                          </Typography>
                        </Box>
                        <ShieldOutlinedIcon
                          sx={{
                            color: warranty.status === 'active' ? 'green' : 'lightgrey',
                            fontSize: 30,
                          }}
                        />
                      </Box>
                      <Typography variant="body2">
                        <strong>Référence Produit :</strong> {warranty.productRef || '-'}
                      </Typography>
                      <Typography variant="body2">
                        <strong>Numéro de Série :</strong> {warranty.serialNumber || '-'}
                      </Typography>
                      <Typography variant="body2">
                        <strong>Date d'Achat :</strong>{' '}
                        {warranty.purchaseDate && !isNaN(new Date(warranty.purchaseDate))
                          ? format(new Date(warranty.purchaseDate), 'dd/MM/yyyy')
                          : 'Date invalide'}
                      </Typography>
                    </CardContent>
                    <CardActions sx={{ justifyContent: 'flex-end' }}>
                  <Button
                    variant="contained" // Modifié de "outlined" à "contained"
                    component={Link}
                    to={`/warranty/${warranty.warrantyNumber}`}
                    sx={{
                      borderRadius: '12px',
                      textTransform: 'none',
                      fontWeight: 'bold',
                    }}
                    color="primary" // S'assurer que la couleur est définie
                  >
                    Détails
                  </Button>
                </CardActions>
                  </Card>
                ))}
             
                  <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
                    <Button
                      variant="contained" // Ajouté
                      color="primary"     // Ajouté
                      onClick={() => handleChangePage(null, page - 1)}
                      disabled={page === 0}
                      sx={{
                        borderRadius: '12px',
                        textTransform: 'none',
                        fontWeight: 'bold',
                        mx: 1,
                      }}
                    >
                      Précédent
                    </Button>
                    <Typography sx={{ mx: 2, mt: 1 }}>
                      Page {page + 1} sur {Math.ceil(filteredWarranties.length / rowsPerPage)}
                    </Typography>
                    <Button
                      variant="contained" // Ajouté
                      color="primary"     // Ajouté
                      onClick={() => handleChangePage(null, page + 1)}
                      disabled={
                        page >= Math.ceil(filteredWarranties.length / rowsPerPage) - 1
                      }
                      sx={{
                        borderRadius: '12px',
                        textTransform: 'none',
                        fontWeight: 'bold',
                        mx: 1,
                      }}
                    >
                      Suivant
                    </Button>
                  </Box>

              </Box>
            </>
          )}
        </>
      )}
    </Container>
  );
}

export default UserWarranties;
