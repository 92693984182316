import React, { useState } from 'react';
import {
  Container,
  Box,
  TextField,
  Typography,
  Button,
  Grid,
  Paper,
  IconButton,
  InputAdornment,
  Snackbar,
  Alert,
  Stack,
  useMediaQuery,
} from '@mui/material';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useTheme } from '@mui/material/styles';
import { loginSuccess } from '../../store/authSlice';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import GroupIcon from '@mui/icons-material/Group';

function Login() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const [formData, setFormData] = useState({
    email: '',
    password: '',
  });
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleSubmit = async () => {
    setLoading(true);
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/users/login`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });
      const data = await response.json();

      if (response.ok) {
        localStorage.setItem('token', data.token);
        const userDetailsResponse = await fetch(`${process.env.REACT_APP_API_URL}/users/details`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${data.token}`,
          },
        });

        const userDetails = await userDetailsResponse.json();

        if (userDetailsResponse.ok) {
          localStorage.setItem('userRole', userDetails.role);
          dispatch(loginSuccess(userDetails));
          navigate(userDetails.role === 'admin' ? '/admin/users' : '/user-warranties');
        } else {
          console.error(
            "Erreur lors de la récupération des détails de l'utilisateur:",
            userDetails.message
          );
        }
      } else {
        setErrorMessage(data.message || 'Erreur de connexion.');
        setSnackbarOpen(true);
      }
    } catch (error) {
      setErrorMessage('Erreur réseau, veuillez réessayer plus tard.');
      setSnackbarOpen(true);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container
      maxWidth={false}
      sx={{
        minHeight: '100vh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        p: { xs: 2, md: 3 },
        backgroundColor: theme.palette.background.default,
      }}
    >
      <Paper
        elevation={0}
        sx={{
          width: '100%',
          maxWidth: '1200px',
          borderRadius: '24px',
          overflow: 'hidden',
          display: 'flex',
          flexDirection: { xs: 'column', md: 'row' },
          boxShadow: '0 8px 32px rgba(0, 0, 0, 0.08)',
        }}
      >
        <Grid container>
          {/* Colonne gauche - Branding et avantages */}
          <Grid
            item
            xs={12}
            md={6}
            order={{ xs: 2, md: 1 }}
            sx={{
              backgroundColor: '#f8fafc',
              p: { xs: 2.5, md: 4 },
            }}
          >
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: 1,
              }}
            >
              {/* En-tête */}
              <Paper
                elevation={0}
                sx={{
                  p: 3,
                  background: `linear-gradient(135deg, ${theme.palette.primary.main}, ${theme.palette.primary.dark})`,
                  color: 'white',
                  borderRadius: '16px',
                  transition: 'transform 0.2s ease-in-out',
                  '&:hover': {
                    transform: 'translateY(-2px)',
                  },
                }}
              >
                <Typography variant="h5" component="h1" sx={{ fontWeight: 700, mb: 1 }}>
                  HANGER TOOLS
                </Typography>
                <Typography variant="subtitle1" sx={{ opacity: 0.9 }}>
                  Des outils conçus pour durer, même dans les conditions les plus exigeantes
                </Typography>
              </Paper>

              {/* Offre principale */}
              <Paper
                elevation={0}
                sx={{
                  p: 3,
                  backgroundColor: theme.palette.secondary.main,
                  color: 'white',
                  borderRadius: '16px',
                  position: 'relative',
                  overflow: 'hidden',
                  '&::before': {
                    content: '""',
                    position: 'absolute',
                    top: 0,
                    right: 0,
                    width: '150px',
                    height: '150px',
                    background:
                      'radial-gradient(circle, rgba(255,255,255,0.1) 0%, rgba(255,255,255,0) 70%)',
                    borderRadius: '50%',
                  },
                }}
              >
                <Typography
                  variant="overline"
                  sx={{
                    color: '#ffd700',
                    letterSpacing: 2,
                    fontWeight: 600,
                  }}
                >
                  OFFRE EXCLUSIVE
                </Typography>
                <Typography variant="h6" sx={{ fontWeight: 700, my: 1 }}>
                  +1 AN DE GARANTIE GRATUITE
                </Typography>
                <Typography variant="body2" sx={{ opacity: 0.9 }}>
                  Pour chaque outil enregistré sur myHanger
                </Typography>
              </Paper>

              {/* Grille d'avantages */}
              <Grid container spacing={2}>
                {[
                  {
                    icon: <VerifiedUserIcon />,
                    title: 'Extension de garantie',
                    description: 'Une année supplémentaire offerte',
                  },
                  {
                    icon: <AccessTimeIcon />,
                    title: 'Suivi en temps réel',
                    description: 'Gestion simplifiée des garanties',
                  },
                  {
                    icon: <NotificationsActiveIcon />,
                    title: 'Alertes personnalisées',
                    description: "Notifications d'expiration",
                  },
                  {
                    icon: <SupportAgentIcon />,
                    title: 'Support prioritaire',
                    description: 'Assistance dédiée',
                  },
                ].map((item, index) => (
                  <Grid item xs={12} sm={6} key={index}>
                    <Paper
                      sx={{
                        p: 2,
                        height: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        textAlign: 'center',
                        borderRadius: '16px',
                        transition: 'all 0.2s ease-in-out',
                        '&:hover': {
                          transform: 'translateY(-2px)',
                          boxShadow: '0 4px 20px rgba(0, 0, 0, 0.05)',
                        },
                      }}
                    >
                      <Box
                        sx={{
                          width: 60,
                          height: 60,
                          borderRadius: '12px',
                          backgroundColor: `${theme.palette.primary.main}15`,
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          mb: 1.5,
                          '& svg': {
                            fontSize: 32,
                            color: theme.palette.primary.main,
                          },
                        }}
                      >
                        {item.icon}
                      </Box>
                      <Typography
                        variant="subtitle1"
                        sx={{
                          fontWeight: 600,
                          mb: 0.5,
                        }}
                      >
                        {item.title}
                      </Typography>
                      <Typography
                        variant="body2"
                        color="text.secondary"
                        sx={{
                          lineHeight: 1.4,
                        }}
                      >
                        {item.description}
                      </Typography>
                    </Paper>
                  </Grid>
                ))}
              </Grid>

              {/* Section de confiance */}
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: 2,
                  mt: 2,
                }}
              >
                <Paper
                  elevation={0}
                  sx={{
                    flex: 1,
                    p: 2,
                    borderRadius: '16px',
                    background: `linear-gradient(135deg, ${theme.palette.primary.main}15, ${theme.palette.secondary.main}15)`,
                    display: 'flex',
                    alignItems: 'center',
                    gap: 2,
                  }}
                >
                  <Box
                    sx={{
                      width: 48,
                      height: 48,
                      borderRadius: '10px',
                      backgroundColor: 'white',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    <GroupIcon sx={{ color: theme.palette.primary.main, fontSize: 24 }} />
                  </Box>
                  <Box>
                    <Typography
                      variant="h6"
                      sx={{ fontWeight: 700, mb: 0.25, fontSize: '1.1rem' }}
                    >
                      20 000+
                    </Typography>
                    <Typography variant="body2" color="text.secondary" sx={{ fontSize: '0.75rem' }}>
                      Professionnels nous font confiance
                    </Typography>
                  </Box>
                </Paper>
              </Box>
            </Box>
          </Grid>

          {/* Colonne droite - Formulaire de connexion */}
          <Grid
            item
            xs={12}
            md={6}
            order={{ xs: 1, md: 2 }}
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              backgroundColor: 'white',
              p: { xs: 3, md: 4 },
            }}
          >
            <Box sx={{ width: '100%', maxWidth: 360 }}>
              <Typography variant="h4" sx={{ fontWeight: 700, mb: 1 }}>
                Connexion
              </Typography>
              <Typography variant="body2" color="text.secondary" sx={{ mb: 3 }}>
                Accédez à votre espace personnel pour gérer vos garanties
              </Typography>

              <TextField
                fullWidth
                name="email"
                label="Adresse Email"
                variant="outlined"
                size="medium"
                margin="normal"
                value={formData.email}
                onChange={handleChange}
                sx={{
                  '& .MuiOutlinedInput-root': {
                    borderRadius: '12px',
                  },
                }}
              />

              <TextField
                fullWidth
                name="password"
                label="Mot de Passe"
                type={showPassword ? 'text' : 'password'}
                variant="outlined"
                size="medium"
                margin="normal"
                value={formData.password}
                onChange={handleChange}
                sx={{
                  '& .MuiOutlinedInput-root': {
                    borderRadius: '12px',
                  },
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={togglePasswordVisibility}
                        edge="end"
                        sx={{ color: theme.palette.text.secondary }}
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />

              <Button
                variant="contained"
                fullWidth
                sx={{
                  mt: 3,
                  mb: 2,
                  py: 1.5,
                  borderRadius: '12px',
                  textTransform: 'none',
                  fontSize: '1rem',
                  fontWeight: 600,
                  boxShadow: 'none',
                  '&:hover': {
                    boxShadow: '0 4px 12px rgba(0, 0, 0, 0.15)',
                  },
                }}
                onClick={handleSubmit}
                disabled={loading}
              >
                {loading ? 'Connexion en cours...' : 'Se connecter'}
              </Button>

              <Box sx={{ mt: 3, textAlign: 'center' }}>
                <Stack spacing={1}>
                  <Typography variant="body2">
                    <Link
                      to="/forgot-password"
                      style={{
                        color: theme.palette.text.secondary,
                        textDecoration: 'none',
                      }}
                    >
                      Mot de passe oublié ?
                    </Link>
                  </Typography>
                  <Typography variant="body2">
                    Nouveau sur myHanger ?{' '}
                    <Link
                      to="/register"
                      style={{
                        color: theme.palette.primary.main,
                        textDecoration: 'none',
                        fontWeight: 600,
                      }}
                    >
                      Créer un compte
                    </Link>
                  </Typography>
                  <Typography variant="body2">
                    <Link
                      to="/resend-activation"
                      style={{
                        color: theme.palette.text.secondary,
                        textDecoration: 'none',
                      }}
                    >
                      Renvoyer le lien d'activation
                    </Link>
                  </Typography>
                </Stack>
              </Box>
            </Box>
          </Grid>
        </Grid>

        <Snackbar
          open={snackbarOpen}
          autoHideDuration={6000}
          onClose={() => setSnackbarOpen(false)}
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        >
          <Alert
            onClose={() => setSnackbarOpen(false)}
            severity="error"
            sx={{ borderRadius: '12px' }}
          >
            {errorMessage}
          </Alert>
        </Snackbar>
      </Paper>
    </Container>
  );
}

export default Login;
