// src/components/resendactivation/ResendActivation.js
import React, { useState } from 'react';
import {
  Container,
  Box,
  TextField,
  Typography,
  Button,
  Alert,
  Paper,
  Divider,
  CircularProgress,
  useTheme,
  useMediaQuery,
} from '@mui/material';

function ResendActivation() {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const handleChange = (e) => {
    setEmail(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setMessage('');
    setError(false);

    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/users/resend-activation`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email }),
      });
      const data = await response.json();

      if (response.ok) {
        setMessage(
          'Un nouvel email d’activation a été envoyé. Veuillez vérifier votre boîte de réception.'
        );
        setError(false);
      } else {
        setMessage(
          data.message || 'Une erreur est survenue. Veuillez vérifier votre adresse email.'
        );
        setError(true);
      }
    } catch (err) {
      console.error("Erreur lors de l'envoi du lien d'activation :", err);
      setMessage(
        'Une erreur est survenue lors de la demande de renvoi. Veuillez réessayer plus tard.'
      );
      setError(true);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container maxWidth="sm" sx={{ p: { xs: 2, sm: 3 }, mt: { xs: 4, sm: 8 }, mb: { xs: 6, sm: 10 } }}>
      <Paper
        elevation={3}
        sx={{
          p: { xs: 3, sm: 4 },
          borderRadius: 2,
          textAlign: 'center',
          boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
        }}
      >
        <Box sx={{ mb: { xs: 2, sm: 3 } }}>
          <Typography
            variant={isMobile ? 'h5' : 'h4'}
            component="h1"
            sx={{ fontWeight: 700, mb: 1 }}
          >
            Renvoyer le lien d'activation
          </Typography>
          <Typography
            variant="body2"
            color="text.secondary"
            sx={{ fontSize: '0.95rem', px: { xs: 1, sm: 0 } }}
          >
            Entrez votre adresse email pour recevoir un nouveau lien d'activation et activer votre
            compte.
          </Typography>
        </Box>
        <Divider sx={{ mb: { xs: 2, sm: 3 } }} />
        <Box component="form" onSubmit={handleSubmit}>
          <TextField
            fullWidth
            label="Adresse Email"
            variant="outlined"
            value={email}
            onChange={handleChange}
            required
            sx={{
              mb: 2,
              bgcolor: 'background.paper',
              borderRadius: 1,
              '& .MuiOutlinedInput-root': {
                borderRadius: '12px',
              },
            }}
          />
          <Button
            type="submit"
            variant="contained"
            color="primary"
            fullWidth
            disabled={loading}
            sx={{
              py: 1.5,
              fontWeight: 'bold',
              textTransform: 'none',
              fontSize: '1rem',
              borderRadius: '12px',
            }}
          >
            {loading ? (
              <CircularProgress size={24} sx={{ color: 'white' }} />
            ) : (
              "Renvoyer le lien d'activation"
            )}
          </Button>
        </Box>
        {message && (
          <Alert
            severity={error ? 'error' : 'success'}
            sx={{
              mt: 3,
              fontSize: '0.95rem',
              textAlign: 'left',
            }}
          >
            {message}
          </Alert>
        )}
      </Paper>
    </Container>
  );
}

export default ResendActivation;
