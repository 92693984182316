import React, { useState, useEffect } from 'react';
import {
  Container,
  Typography,
  Box,
  Grid,
  Paper,
  CircularProgress,
  Card,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { Line, Bar } from 'react-chartjs-2';
import 'chart.js/auto';
import { useNavigate } from 'react-router-dom';

const AdminDashboard = () => {
  const [dashboardData, setDashboardData] = useState(null);
  const [loading, setLoading] = useState(true);
  const API_URL = process.env.REACT_APP_API_URL;
  const navigate = useNavigate();

  useEffect(() => {
    const fetchDashboardData = async () => {
      try {
        const token = localStorage.getItem('adminToken');
        if (!token) {
          navigate('/admin/login');
          return;
        }
        const response = await fetch(`${API_URL}/admin/dashboard`, {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        });
        if (!response.ok) throw new Error('Erreur API');
        const data = await response.json();
        setDashboardData(data);
      } catch (error) {
        console.error('Erreur lors de la récupération des données du tableau de bord:', error);
        setDashboardData(null);
      } finally {
        setLoading(false);
      }
    };

    fetchDashboardData();
  }, [API_URL, navigate]);

  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress />
      </Box>
    );
  }

  if (!dashboardData) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <Typography variant="h6">Erreur lors de la récupération des données du tableau de bord</Typography>
      </Box>
    );
  }

  const lineChartData = {
    labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
    datasets: [
      {
        label: 'This Year',
        data: dashboardData?.monthlyWarrantiesThisYear || new Array(12).fill(0),
        borderColor: '#3f51b5',
        backgroundColor: 'rgba(63, 81, 181, 0.2)',
        fill: true,
      },
      {
        label: 'Last Year',
        data: dashboardData?.monthlyWarrantiesLastYear || new Array(12).fill(0),
        borderColor: '#ff5722',
        backgroundColor: 'rgba(255, 87, 34, 0.2)',
        fill: true,
      },
    ],
  };

  const usersChartData = {
    labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
    datasets: [
      {
        label: 'Utilisateurs Enregistrés',
        data: dashboardData?.monthlyUsersThisYear || new Array(12).fill(0),
        backgroundColor: 'rgba(75, 192, 192, 0.6)',
      },
    ],
  };

  return (
    <Container maxWidth="lg">
      <Box my={4}>
        <Typography variant="h4" align="center" gutterBottom>
          Tableau de Bord - Administration
        </Typography>

        {/* Statistiques globales */}
        <Grid container spacing={3} sx={{ mb: 4 }}>
          <Grid item xs={12} sm={4}>
            <Card sx={{ p: 3, textAlign: 'center' }}>
              <Typography variant="h6" color="textSecondary">
                Nombre Total de Garanties
              </Typography>
              <Typography variant="h3" color="primary">
                {dashboardData.totalWarranties ?? 0}
              </Typography>
            </Card>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Card sx={{ p: 3, textAlign: 'center' }}>
              <Typography variant="h6" color="textSecondary">
                Garanties Actives / Expirées
              </Typography>
              <Typography variant="h3" color="primary">
                {dashboardData.activeWarranties ?? 0} / {dashboardData.expiredWarranties ?? 0}
              </Typography>
            </Card>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Card sx={{ p: 3, textAlign: 'center' }}>
              <Typography variant="h6" color="textSecondary">
                Nouveaux Enregistrements (ce mois-ci)
              </Typography>
              <Typography variant="h3" color="primary">
                {dashboardData.newWarrantiesThisMonth ?? 0}
              </Typography>
            </Card>
          </Grid>
        </Grid>

        {/* Graphiques */}
        <Grid container spacing={3} sx={{ mb: 4 }}>
          <Grid item xs={12} md={6}>
            <Card sx={{ p: 3 }}>
              <Typography variant="h6" gutterBottom>
                Garanties Enregistrées par Mois
              </Typography>
              <Line data={lineChartData} />
            </Card>
          </Grid>
          <Grid item xs={12} md={6}>
            <Card sx={{ p: 3 }}>
              <Typography variant="h6" gutterBottom>
                Utilisateurs Enregistrés par Mois
              </Typography>
              <Bar data={usersChartData} />
            </Card>
          </Grid>
        </Grid>

        {/* Tableaux */}
        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <Card sx={{ p: 3 }}>
              <Typography variant="h6" gutterBottom>
                Top Produits avec Garanties
              </Typography>
              <TableContainer component={Paper}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Nom du Produit</TableCell>
                      <TableCell>Réf Produit</TableCell>
                      <TableCell>Nombre</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {dashboardData.topProducts?.map((product) => (
                      <TableRow key={product.productId}>
                        <TableCell>{product.productName}</TableCell>
                        <TableCell>{product.productRef}</TableCell>
                        <TableCell>{product.warrantyCount}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Card>
          </Grid>
          <Grid item xs={12} md={6}>
            <Card sx={{ p: 3 }}>
              <Typography variant="h6" gutterBottom>
                Garanties par Catégorie de Produit
              </Typography>
              <TableContainer component={Paper}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Catégorie</TableCell>
                      <TableCell>Nombre de Garanties</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {dashboardData.warrantiesByCategory?.map((category) => (
                      <TableRow key={category.categoryName}>
                        <TableCell>{category.categoryName}</TableCell>
                        <TableCell>{category.warrantyCount}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Card>
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
};

export default AdminDashboard;
