import React, { useEffect, useState } from 'react'; // Importation de useEffect et useState depuis React
import { Navigate, useLocation } from 'react-router-dom'; // Importation de Navigate et useLocation depuis react-router-dom
import { jwtDecode } from 'jwt-decode';

const PrivateRoute = ({ children }) => {
  const location = useLocation();
  const [authStatus, setAuthStatus] = useState('checking');

  useEffect(() => {
    const token = localStorage.getItem('adminToken');
    if (!token) {
      setAuthStatus('unauthorized');
      return;
    }

    try {
      const decodedToken = jwtDecode(token);
      console.log("Decoded Token:", decodedToken);

      // Vérification de l'expiration du jeton
      if (decodedToken.exp * 1000 > Date.now()) {
        if (decodedToken.role === 'admin') {
          setAuthStatus('authorized');
        } else {
          setAuthStatus('unauthorized');
        }
      } else {
        localStorage.removeItem('adminToken');
        setAuthStatus('unauthorized');
      }
    } catch (error) {
      console.error("Invalid token:", error);
      localStorage.removeItem('adminToken');
      setAuthStatus('unauthorized');
    }
  }, []);

  if (authStatus === 'checking') {
    return <div>Loading...</div>; // Affichage d'un composant de chargement
  }

  if (authStatus === 'unauthorized') {
    return <Navigate to={location.pathname.startsWith("/admin") ? "/admin" : "/login"} replace />;
  }

  return children;
};

export default PrivateRoute;