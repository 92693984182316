// src/components/Footer.js
import React from 'react';
import { Box, Typography, Link, useTheme, useMediaQuery } from '@mui/material';

function Footer() {
  const currentYear = new Date().getFullYear();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Box
      component="footer"
      sx={{
        backgroundColor: theme.palette.background.paper,
        p: { xs: 2, sm: 3 },
        mt: 'auto',
        textAlign: 'center',
        borderTop: `1px solid ${theme.palette.divider}`,
      }}
    >
      <Typography
        variant="body2"
        sx={{
          color: theme.palette.text.secondary,
          fontSize: isMobile ? '0.875rem' : '1rem',
        }}
      >
        © {currentYear} Hanger. Tous droits réservés.
      </Typography>
      <Box sx={{ mt: 1 }}>
        <Link
          href="/terms"
          color="inherit"
          underline="none"
          sx={{ mx: 1, fontSize: '0.875rem' }}
        >
          Conditions d'utilisation
        </Link>
        <Link
          href="/privacy"
          color="inherit"
          underline="none"
          sx={{ mx: 1, fontSize: '0.875rem' }}
        >
          Politique de confidentialité
        </Link>
      </Box>
    </Box>
  );
}

export default Footer;
