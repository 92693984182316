import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import {
  Container,
  TextField,
  Button,
  Typography,
  Stepper,
  Step,
  StepLabel,
  Box,
  Paper,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Tabs,
  Tab,
  Grid,
  IconButton,
  useTheme,
  useMediaQuery,
  InputAdornment,
  Autocomplete,
  } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import CloseIcon from '@mui/icons-material/Close';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';

// Import des logos des revendeurs
import sobricoLogo from '../../assets/images/retailers/sobrico-logo.png';
import afdbLogo from '../../assets/images/retailers/afdb-logo.png';
import amazonLogo from '../../assets/images/retailers/amazon-logo.png';
import manomanoLogo from '../../assets/images/retailers/manomano-logo.png';
import leroyMerlinLogo from '../../assets/images/retailers/leroymerlin-logo.png';
import defaultLogo from '../../assets/images/retailers/default-logo.png';

function ProductSelectionComponent({
  products,
  combos,
  registrationType,
  setRegistrationType,
  handleProductSelection,
  handleComboSelection,
  selectedProduct,
  selectedCombo,
  comboProducts,
  setComboProducts,
  listSerialNumber,
  setListSerialNumber,
  validateSerialNumber,
  comboSerialValidation,
  setComboSerialValidation,
  error,
  setError,
}) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [tabValue, setTabValue] = useState(registrationType === 'combo' ? 1 : 0);

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
    setRegistrationType(newValue === 0 ? 'single' : 'combo');
    handleProductSelection(''); // Réinitialise la sélection du produit
    handleComboSelection(''); // Réinitialise la sélection du pack
    window.scrollTo(0, 0); // Scroll vers le haut
  };

  const handleComboProductSerialChange = (itemIndex, quantityIndex, value) => {
    const sanitizedValue = value.replace(/\D/g, ''); // Supprime tout caractère non numérique
    if (sanitizedValue.length > 11) return; // Ne permet pas plus de 11 chiffres

    const { isValid, errorMessage } = validateSerialNumber(sanitizedValue);

    // Mettre à jour les numéros de série dans `comboProducts`
    setComboProducts((prevProducts) => {
      const newProducts = [...prevProducts];
      if (!newProducts[itemIndex].serialNumbers) {
        newProducts[itemIndex].serialNumbers = [];
      }
      newProducts[itemIndex].serialNumbers[quantityIndex] = sanitizedValue;
      return newProducts;
    });

    // Mettre à jour la validation des numéros de série dans `comboSerialValidation`
    setComboSerialValidation((prevValidation) => {
      const newValidation = [...prevValidation];
      if (!newValidation[itemIndex]) {
        newValidation[itemIndex] = [];
      }
      newValidation[itemIndex][quantityIndex] = isValid;
      return newValidation;
    });

    // Mettre à jour les messages d'erreur
    setError(isValid ? '' : errorMessage);
  };

  return (
    <Box sx={{ width: '100%', mt: 3 }}>
      <Tabs
        value={tabValue}
        onChange={handleTabChange}
        centered
        sx={{
          '& .MuiTabs-indicator': { backgroundColor: theme.palette.primary.main },
          '& .MuiTab-root': { fontWeight: 'bold', color: theme.palette.text.secondary },
          '& .Mui-selected': { color: `${theme.palette.primary.main} !important` },
        }}
      >
        <Tab label="Produit seul" />
        <Tab label="Pack d'outils" />
      </Tabs>

      <Box sx={{ mt: 4 }}>
        {/* Sélection d'un produit */}
        {tabValue === 0 && products?.length > 0 && (
          <>
            {products && (
              <Autocomplete
                options={products.sort((a, b) => -b.category.localeCompare(a.category))}
                groupBy={(product) => product.category}
                getOptionLabel={(product) =>
                  `${product.model} (Hanger Model: ${product.productNumber})`
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Sélectionnez un outil"
                    variant="outlined"
                    fullWidth
                    sx={{
                      '& .MuiOutlinedInput-root': {
                        borderRadius: '12px',
                      },
                    }}
                  />
                )}
                renderOption={(props, product) => (
                  <li {...props} key={product._id}>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                      <img
                        src={
                          product.imageUrl ||
                          `${process.env.PUBLIC_URL}/default-product-image.png`
                        }
                        alt={product.model}
                        style={{ width: '50px', height: 'auto', borderRadius: 4 }}
                      />
                      <Typography>
                        {product.model} (Hanger Model: {product.productNumber})
                      </Typography>
                    </Box>
                  </li>
                )}
                onChange={(event, newValue) => {
                  handleProductSelection(newValue ? newValue._id : '');
                }}
                value={products.find((product) => product._id === selectedProduct) || null}
                isOptionEqualToValue={(option, value) => option._id === value._id}
              />
            )}

            {/* Champ pour le numéro de série */}
            {selectedProduct && (
              <Box sx={{ mt: 3 }}>
                <TextField
                  fullWidth
                  label="Numéro de série"
                  variant="outlined"
                  value={listSerialNumber}
                  onChange={(e) => {
                    const { value } = e.target;
                    const { isValid, errorMessage } = validateSerialNumber(value);
                    setListSerialNumber(value); // Met à jour la valeur
                    setError(isValid ? '' : errorMessage); // Affiche les messages d'erreur
                  }}
                  error={!!error} // Affiche une bordure rouge si une erreur existe
                  helperText={
                    error ||
                    'Saisissez un numéro de série composé de 11 chiffres uniquement.'
                  }
                  inputProps={{
                    maxLength: 11, // Limite la saisie à 11 caractères
                  }}
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      borderRadius: '12px',
                    },
                  }}
                />
              </Box>
            )}
          </>
        )}

        {/* Sélection d'un pack */}
        {tabValue === 1 && combos?.length > 0 && (
          <FormControl fullWidth>
            <InputLabel>Sélectionnez un pack d'outils</InputLabel>
            <Select
              value={selectedCombo}
              onChange={(e) => handleComboSelection(e.target.value)}
              label="Sélectionnez un pack d'outils"
              MenuProps={{
                PaperProps: {
                  style: {
                    maxHeight: 300,
                  },
                },
              }}
            >
              {combos.map((combo) => (
                <MenuItem key={combo._id} value={combo._id}>
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                    <img
                      src={
                        combo.imageUrl || `${process.env.PUBLIC_URL}/default-combo-image.png`
                      }
                      alt={combo.name}
                      style={{ width: '75px', height: 'auto', borderRadius: 4 }}
                    />
                    <Typography>
                      {combo.name} (Hanger Model: {combo.hangerModel})
                    </Typography>
                  </Box>
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
      </Box>

      {/* Affichage des produits inclus dans le pack sélectionné */}
      {tabValue === 1 && selectedCombo && (
        <Box
          sx={{
            mt: 3,
            p: 2,
            backgroundColor: theme.palette.background.paper,
            borderRadius: 2,
            boxShadow: 1,
          }}
        >
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
            <img
              src={
                combos.find((combo) => combo._id === selectedCombo)?.imageUrl ||
                `${process.env.PUBLIC_URL}/default-combo-image.png`
              }
              alt="Pack sélectionné"
              style={{ width: '60px', height: 'auto', borderRadius: 4 }}
            />
            <Typography variant="body1">
              Pack sélectionné :{' '}
              <strong>
                {combos.find((combo) => combo._id === selectedCombo)?.name || 'N/A'} (Hanger
                Model: {combos.find((combo) => combo._id === selectedCombo)?.hangerModel || 'N/A'})
              </strong>
            </Typography>
          </Box>

          <Typography variant="h6" sx={{ mt: 2 }}>
            Produits inclus dans le pack :
          </Typography>

          {Array.isArray(comboProducts) &&
            comboProducts.flatMap((item, itemIndex) =>
              Array(item.quantity)
                .fill()
                .map((_, quantityIndex) => (
                  <Box
                    key={`${itemIndex}-${quantityIndex}`}
                    sx={{ display: 'flex', alignItems: 'center', gap: 2, mt: 1 }}
                  >
                    <img
                      src={
                        item.productId?.imageUrl ||
                        `${process.env.PUBLIC_URL}/default-product-image.png`
                      }
                      alt={item.productId?.model || 'Product'}
                      style={{ width: '50px', height: 'auto', borderRadius: 4 }}
                    />
                    <Box sx={{ flex: 1 }}>
                      <Typography>{item.productId?.model || 'N/A'}</Typography>
                      <Typography variant="caption">
                        (Hanger Model: {item.productId?.productNumber || 'N/A'})
                      </Typography>
                    </Box>
                    <TextField
                      label="Numéro de série"
                      variant="outlined"
                      size="small"
                      value={comboProducts[itemIndex]?.serialNumbers?.[quantityIndex] || ''}
                      onChange={(e) =>
                        handleComboProductSerialChange(itemIndex, quantityIndex, e.target.value)
                      }
                      inputProps={{
                        maxLength: 11,
                        pattern: '\\d*',
                      }}
                      error={comboSerialValidation[itemIndex]?.[quantityIndex] === false}
                      helperText={
                        comboSerialValidation[itemIndex]?.[quantityIndex] === false
                          ? 'Numéro de série invalide (11 chiffres requis)'
                          : 'Saisissez un numéro de série'
                      }
                      sx={{
                        '& .MuiOutlinedInput-root': {
                          borderRadius: '12px',
                        },
                      }}
                    />
                  </Box>
                ))
            )}
        </Box>
      )}
    </Box>
  );
}

function WarrantyRegistrationSteps() {
  const API_URL = process.env.REACT_APP_API_URL;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [step, setStep] = useState(1);
  const [listSerialNumber, setListSerialNumber] = useState('');
  const [productInfo, setProductInfo] = useState(null);
  const [purchaseDate, setPurchaseDate] = useState('');
  const [retailerName, setRetailerName] = useState('');
  const [invoiceFile, setInvoiceFile] = useState(null);
  const [labelPhotoFile, setLabelPhotoFile] = useState(null);
  const [error, setError] = useState('');
  const navigate = useNavigate();
  const [invoiceNumber, setInvoiceNumber] = useState('');
  const [registrationType, setRegistrationType] = useState('single');
  const [selectedProduct, setSelectedProduct] = useState('');
  const [selectedCombo, setSelectedCombo] = useState('');
  const [productList, setProductList] = useState([]);
  const [comboList, setComboList] = useState([]);
  const [comboProducts, setComboProducts] = useState([]);
  const [comboInfo, setComboInfo] = useState(null);
  const [singleProduct, setSingleProduct] = useState(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('error');
  const [comboSerialValidation, setComboSerialValidation] = useState([]);
  const [isValidPurchaseDate, setIsValidPurchaseDate] = useState(true);
  const [open, setOpen] = useState(false);
  const today = new Date();
  const minDate = new Date(today.setDate(today.getDate() - 30));
  const maxDate = new Date();

  const isCombo = comboProducts && comboProducts.length > 0;

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const areAllComboSerialsValid = () => {
    return comboProducts.every((item) =>
      item.serialNumbers && item.serialNumbers.every((serial) => validateSerialNumber(serial).isValid)
    );
  };

  const handlePurchaseDateChange = (date) => {
    setPurchaseDate(date);

    const purchaseDate = new Date(date);
    const today = new Date();
    const daysDifference = (today - purchaseDate) / (1000 * 60 * 60 * 24);

    if (daysDifference >= 0 && daysDifference <= 30) {
      setIsValidPurchaseDate(true);
    } else {
      setIsValidPurchaseDate(false);
    }
  };

  const validateSerialNumber = (value) => {
    const regex = /^\d{11}$/; // Exactement 11 chiffres
    const isValid = regex.test(value);
    const errorMessage = isValid
      ? ''
      : 'Le numéro de série doit contenir exactement 11 chiffres.';
    return { isValid, errorMessage };
  };

  const steps = ['Identification du produit', "Informations d'achat", 'Récapitulatif'];

  useEffect(() => {
    fetchProductsAndCombos();
  }, []);

  const fetchProductsAndCombos = async () => {
    try {
      const productsResponse = await fetch(`${API_URL}/products/public`);
      const combosResponse = await fetch(`${API_URL}/combos/public`);

      if (productsResponse.ok && combosResponse.ok) {
        const products = await productsResponse.json();
        const combos = await combosResponse.json();
        setProductList(products);
        setComboList(combos);
      } else {
        setError('Erreur lors du chargement des produits et packs');
      }
    } catch (error) {
      console.error('Erreur:', error);
      setError('Erreur de connexion au serveur');
    }
  };

  const MAX_FILE_SIZE = 5 * 1024 * 1024; // Taille maximale : 5 Mo

  const handleFileUpload = (event, setFileFunction) => {
    const file = event.target.files[0];
    if (file) {
      if (file.size > MAX_FILE_SIZE) {
        setSnackbarMessage('La taille du fichier ne doit pas dépasser 5 Mo.');
        setSnackbarSeverity('error');
        setSnackbarOpen(true);
        return;
      }
      setError('');
      setFileFunction(file);
    }
  };

    const handleNext = () => {
    if (step === 2) {
      fetchRecapInfo();
    }
    setStep(step + 1);
    window.scrollTo(0, 0); // Scroll vers le haut
  };

  const handlePrevious = () => {
    if (step > 1) {
      setStep(step - 1);
      window.scrollTo(0, 0); // Scroll vers le haut
    }
  };

  const handleProductSelection = (productId) => {
    setSelectedProduct(productId);
  };

  const handleComboSelection = (comboId) => {
    const combo = comboList.find((c) => c._id === comboId);
    setSelectedCombo(comboId);
    if (combo) {
      const expandedComboProducts = combo.items.map((item) => ({
        ...item,
        serialNumbers: Array(item.quantity).fill(''),
      }));
      setComboProducts(expandedComboProducts);
    }
  };

  const fetchRecapInfo = async () => {
    if (registrationType === 'single') {
      try {
        if (!selectedProduct) {
          throw new Error('Aucun produit sélectionné.');
        }

        const token = localStorage.getItem('token');
        if (!token) {
          throw new Error('Token non trouvé.');
        }

        const response = await fetch(`${API_URL}/products/${selectedProduct}`, {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        });

        if (response.ok) {
          const data = await response.json();
          setProductInfo(data);
        } else {
          console.error('Erreur lors de la récupération du produit :', response.statusText);
        }
      } catch (error) {
        console.error('Erreur dans fetchRecapInfo :', error.message);
      }
    } else if (registrationType === 'combo') {
      try {
        const token = localStorage.getItem('token');
        if (!token) {
          throw new Error('Token non trouvé.');
        }

        const response = await fetch(`${API_URL}/combos/${selectedCombo}`, {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        });

        if (response.ok) {
          const data = await response.json();
          setComboInfo(data);
        } else {
          console.error('Erreur lors de la récupération des informations du pack');
        }
      } catch (error) {
        console.error('Erreur:', error);
      }
    }
  };

  const handleSingleRegistration = async () => {
    try {
      const formData = new FormData();
      formData.append('serialNumber', listSerialNumber);
      formData.append('productId', selectedProduct);
      formData.append('purchaseDate', purchaseDate);
      formData.append('retailerName', retailerName);
      formData.append('invoiceNumber', invoiceNumber);
      formData.append('invoice', invoiceFile);
      formData.append('label', labelPhotoFile);

      const response = await fetch(`${API_URL}/warranty/register`, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
        body: formData,
      });

      if (response.ok) {
        const result = await response.json();
        setSnackbarMessage('Garantie enregistrée avec succès !');
        setSnackbarSeverity('success');
        setSnackbarOpen(true);

        setSingleProduct({
          serialNumber: listSerialNumber,
          productId: productInfo,
        });

        setStep(4);
      } else {
        const errorData = await response.json();
        setSnackbarMessage(errorData.message || 'Une erreur est survenue.');
        setSnackbarSeverity('error');
        setSnackbarOpen(true);
      }
    } catch (error) {
      setSnackbarMessage('Erreur inattendue. Veuillez réessayer.');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    }
  };

  const handleComboRegistration = async () => {
    try {
      const formData = new FormData();
      formData.append('comboId', selectedCombo);
      formData.append('purchaseDate', purchaseDate);
      formData.append('retailerName', retailerName);
      formData.append('invoiceNumber', invoiceNumber);
      formData.append('invoice', invoiceFile);
      formData.append('label', labelPhotoFile);

      const productsData = comboProducts.flatMap((item) =>
        item.serialNumbers.map((serialNumber) => ({
          serialNumber,
          productId: item.productId?._id || item.productId,
        }))
      );

      formData.append('products', JSON.stringify(productsData));

      const response = await fetch(`${API_URL}/warranty/register-combo`, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
        body: formData,
      });

      if (response.ok) {
        const result = await response.json();
        setSnackbarMessage('Garanties enregistrées avec succès !');
        setSnackbarSeverity('success');
        setSnackbarOpen(true);

        setStep(4);
      } else {
        const errorData = await response.json();
        setSnackbarMessage(errorData.message || 'Une erreur est survenue.');
        setSnackbarSeverity('error');
        setSnackbarOpen(true);
      }
    } catch (error) {
      setSnackbarMessage('Erreur inattendue. Veuillez réessayer.');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    }
  };

  return (
    <Container maxWidth="md" sx={{ py: { xs: 2, sm: 4 } }}>
      <Helmet>
        <title>Enregistrement de Garantie - myHanger</title>
        <meta
          name="description"
          content="Enregistrez la garantie de vos outils Hanger-tools sur myHanger en quelques étapes simples."
        />
      </Helmet>

      {/* Stepper */}
      <Box sx={{ mt: 3, mb: 5 }}>
        <Stepper activeStep={step - 1} alternativeLabel>
          {steps.map((label, index) => (
            <Step key={index}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
      </Box>

      {/* Étape 1 - Identification du produit */}
      {step === 1 && (
        <Box sx={{ mt: 5, mb: 5 }}>
          <Paper sx={{ p: { xs: 2, sm: 4 }, borderRadius: 2, boxShadow: 3 }}>
            <Typography variant={isMobile ? 'h5' : 'h4'} align="center" gutterBottom>
              Identification de l'outil
            </Typography>
            <ProductSelectionComponent
              products={productList}
              combos={comboList}
              registrationType={registrationType}
              setRegistrationType={setRegistrationType}
              handleProductSelection={handleProductSelection}
              handleComboSelection={handleComboSelection}
              selectedProduct={selectedProduct}
              selectedCombo={selectedCombo}
              comboProducts={comboProducts}
              setComboProducts={setComboProducts}
              listSerialNumber={listSerialNumber}
              setListSerialNumber={setListSerialNumber}
              validateSerialNumber={validateSerialNumber}
              comboSerialValidation={comboSerialValidation}
              setComboSerialValidation={setComboSerialValidation}
              error={error}
              setError={setError}
            />
            <Box sx={{ mt: 4, display: 'flex', justifyContent: 'center' }}>
              <Button
                variant="contained"
                sx={{ width: '150px', py: 1.5, borderRadius: '12px', fontWeight: 'bold' }}
                disabled={
                  (registrationType === 'single' &&
                    (!selectedProduct ||
                      !listSerialNumber ||
                      !validateSerialNumber(listSerialNumber).isValid)) ||
                  (registrationType === 'combo' && (!selectedCombo || !areAllComboSerialsValid()))
                }
                onClick={handleNext}
              >
                Suivant
              </Button>
            </Box>
          </Paper>
        </Box>
      )}

      {/* Étape 2 - Informations d'achat */}
      {step === 2 && (
        <Box sx={{ mt: 5 }}>
          <Paper sx={{ p: { xs: 2, sm: 4 }, borderRadius: 2, boxShadow: 3 }}>
            <Typography variant={isMobile ? 'h5' : 'h4'} align="center" gutterBottom>
              Informations d'achat
            </Typography>

            {/* Formulaire */}
            <Box sx={{ mt: 4 }}>
              <Grid container spacing={4}>
                {/* Date d'achat */}
                <Grid item xs={12} sm={6}>
                  <Typography variant="h6" sx={{ mb: 2 }}>
                    Date d'achat
                  </Typography>
                  <DatePicker
                    selected={purchaseDate}
                    onChange={handlePurchaseDateChange}
                    minDate={minDate}
                    maxDate={maxDate}
                    dateFormat="dd/MM/yyyy"
                    customInput={
                      <TextField
                        label="Date d'achat"
                        variant="outlined"
                        fullWidth
                        value={
                          purchaseDate ? new Date(purchaseDate).toLocaleDateString('fr-FR') : ''
                        }
                        error={purchaseDate && !isValidPurchaseDate}
                        helperText={
                          purchaseDate
                            ? isValidPurchaseDate
                              ? 'La date est valide (moins de 30 jours).'
                              : 'La date doit être dans les 30 jours suivant l\'achat.'
                            : 'Veuillez sélectionner une date valide.'
                        }
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <CalendarTodayIcon />
                            </InputAdornment>
                          ),
                        }}
                        sx={{
                          '& .MuiOutlinedInput-root': {
                            borderRadius: '12px',
                          },
                        }}
                      />
                    }
                    popperProps={{
                      modifiers: [
                        {
                          name: 'offset',
                          options: {
                            offset: [0, 10],
                          },
                        },
                      ],
                    }}
                    popperPlacement="top-start"
                    popperClassName="custom-datepicker"
                  />
                </Grid>

                {/* Numéro de facture */}
                <Grid item xs={12} sm={6}>
                  <Typography variant="h6" sx={{ mb: 2 }}>
                    Numéro de facture
                  </Typography>
                  <TextField
                    fullWidth
                    label="Numéro de facture"
                    variant="outlined"
                    value={invoiceNumber}
                    onChange={(e) => setInvoiceNumber(e.target.value)}
                    error={!invoiceNumber || invoiceNumber.length < 3}
                    helperText={
                      !invoiceNumber
                        ? 'Le numéro de facture est obligatoire.'
                        : invoiceNumber.length < 8
                        ? 'Le numéro de facture doit contenir au moins 8 caractères.'
                        : ''
                    }
                    sx={{
                      '& .MuiOutlinedInput-root': {
                        borderRadius: '12px',
                      },
                    }}
                  />
                </Grid>

                {/* Nom du revendeur */}
                <Grid item xs={12}>
                  <Typography variant="h6" sx={{ mb: 2 }}>
                    Nom du revendeur
                  </Typography>
                  <Grid container spacing={2}>
                    {[
                      {
                        name: 'Au Forum Du Batiment',
                        value: 'Au Forum Du Batiment',
                        logoPath: afdbLogo,
                      },
                      { name: 'Sobrico', value: 'Sobrico', logoPath: sobricoLogo },
                      { name: 'Amazon', value: 'Amazon', logoPath: amazonLogo },
                      { name: 'Manomano', value: 'Manomano', logoPath: manomanoLogo },
                      { name: 'Leroy Merlin', value: 'Leroy Merlin', logoPath: leroyMerlinLogo },
                      { name: 'Autres', value: 'Autres', logoPath: defaultLogo },
                    ].map((retailer) => (
                      <Grid item xs={6} sm={4} key={retailer.value}>
                        <Paper
                          onClick={() => setRetailerName(retailer.value)}
                          sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'center',
                            p: 2,
                            cursor: 'pointer',
                            border:
                              retailerName === retailer.value
                                ? `2px solid ${theme.palette.primary.main}`
                                : '1px solid #ddd',
                            borderRadius: 2,
                            boxShadow:
                              retailerName === retailer.value
                                ? '0px 4px 12px rgba(233, 64, 74, 0.4)'
                                : '0px 4px 12px rgba(0, 0, 0, 0.1)',
                            '&:hover': {
                              boxShadow: '0px 4px 12px rgba(233, 64, 74, 0.3)',
                            },
                            height: '100%', // Pour uniformiser la hauteur
                          }}
                        >
                          <img
                            src={retailer.logoPath}
                            alt={retailer.name}
                            style={{ width: '60px', height: 'auto', marginBottom: '8px' }}
                          />
                          <Typography
                            variant="body2"
                            align="center"
                            sx={{ fontWeight: 'bold', fontSize: '0.9rem' }}
                          >
                            {retailer.name}
                          </Typography>
                        </Paper>
                      </Grid>
                    ))}
                  </Grid>
                </Grid>

                {/* Téléchargements */}
                <Grid item xs={12}>
                  <Typography variant="h6" sx={{ mb: 2 }}>
                    Téléchargements
                  </Typography>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                      <Paper
                        sx={{
                          p: 2,
                          display: 'flex',
                          flexDirection: 'column',
                          boxShadow: 1,
                          borderRadius: 2,
                        }}
                      >
                        <Typography variant="body1" sx={{ mb: 2 }}>
                          Télécharger la facture :
                        </Typography>
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                          <Button
                            variant="outlined"
                            component="label"
                            sx={{
                              color: theme.palette.primary.main,
                              borderColor: theme.palette.primary.main,
                              borderRadius: 1,
                              textTransform: 'none',
                            }}
                          >
                            Choisir un fichier
                            <input
                              type="file"
                              hidden
                              onChange={(e) => handleFileUpload(e, setInvoiceFile)}
                            />
                          </Button>
                          {invoiceFile && (
                            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                              <InsertDriveFileIcon sx={{ color: theme.palette.primary.main }} />
                              <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
                                {invoiceFile.name}
                              </Typography>
                            </Box>
                          )}
                        </Box>
                        <Typography variant="caption" sx={{ mt: 1, color: 'text.secondary' }}>
                          Taille maximale du fichier : 5 Mo.
                        </Typography>
                      </Paper>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Paper
                        sx={{
                          p: 2,
                          display: 'flex',
                          flexDirection: 'column',
                          boxShadow: 1,
                          borderRadius: 2,
                        }}
                      >
                        <Typography variant="body1" sx={{ mb: 2 }}>
                          Télécharger une photo de l'étiquette signalétique :
                        </Typography>
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                          <Button
                            variant="outlined"
                            component="label"
                            sx={{
                              color: theme.palette.primary.main,
                              borderColor: theme.palette.primary.main,
                              borderRadius: 1,
                              textTransform: 'none',
                            }}
                          >
                            Choisir un fichier
                            <input
                              type="file"
                              hidden
                              onChange={(e) => handleFileUpload(e, setLabelPhotoFile)}
                            />
                          </Button>
                          {labelPhotoFile && (
                            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                              <InsertDriveFileIcon sx={{ color: theme.palette.primary.main }} />
                              <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
                                {labelPhotoFile.name}
                              </Typography>
                            </Box>
                          )}
                        </Box>
                        <Typography variant="caption" sx={{ mt: 1, color: 'text.secondary' }}>
                          Taille maximale du fichier : 5 Mo.
                        </Typography>
                      </Paper>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Box>

            {/* Boutons de navigation */}
            <Box sx={{ mt: 5, display: 'flex', justifyContent: 'space-between' }}>
              <Button
                variant="contained"
                onClick={handlePrevious}
                sx={{ width: '150px', py: 1.5, borderRadius: '12px', fontWeight: 'bold' }}
              >
                Précédent
              </Button>
              <Button
                variant="contained"
                onClick={handleNext}
                disabled={
                  !purchaseDate ||
                  !retailerName ||
                  !invoiceFile ||
                  !labelPhotoFile ||
                  !isValidPurchaseDate ||
                  !invoiceNumber ||
                  invoiceNumber.length < 3
                }
                sx={{ width: '150px', py: 1.5, borderRadius: '12px', fontWeight: 'bold' }}
              >
                Suivant
              </Button>
            </Box>
          </Paper>
        </Box>
      )}

      {/* Snackbar */}
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={4000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert onClose={handleSnackbarClose} severity={snackbarSeverity} sx={{ width: '100%' }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>

      {/* Étape 3 - Récapitulatif */}
      {step === 3 && (
        <Box sx={{ mt: 5 }}>
          <Paper sx={{ p: { xs: 2, sm: 4 }, borderRadius: 2, boxShadow: 3 }}>
            <Typography variant={isMobile ? 'h5' : 'h4'} align="center" gutterBottom>
              Récapitulatif
            </Typography>
            <Box sx={{ mt: 4 }}>
              {registrationType === 'single' ? (
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    gap: 2,
                  }}
                >
                  <img
                    src={productInfo?.imageUrl || '/default-product-image.png'}
                    alt={productInfo?.model || 'Produit'}
                    style={{
                      width: '150px',
                      height: 'auto',
                      borderRadius: 8,
                      boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
                    }}
                  />
                  <Box sx={{ mt: 2, width: '100%' }}>
                    <Typography variant="subtitle1" gutterBottom>
                      <strong>Modèle :</strong> {productInfo?.model || 'Non disponible'}
                    </Typography>
                    <Typography variant="subtitle1" gutterBottom>
                      <strong>Catégorie :</strong> {productInfo?.category || 'Non disponible'}
                    </Typography>
                    <Typography variant="subtitle1" gutterBottom>
                      <strong>Hanger Model :</strong> {productInfo?.productNumber || 'Non disponible'}
                    </Typography>
                    <Typography variant="subtitle1" gutterBottom>
                      <strong>Numéro de série :</strong> {listSerialNumber || 'Non renseigné'}
                    </Typography>
                  </Box>
                </Box>
              ) : (
                <>
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      gap: 2,
                    }}
                  >
                    <img
                      src={comboInfo?.imageUrl || '/default-combo-image.png'}
                      alt={comboInfo?.name}
                      style={{ width: '150px', height: 'auto', borderRadius: 4 }}
                    />
                    <Box sx={{ mt: 2, width: '100%' }}>
                      <Typography variant="subtitle1">
                        <strong>Produit :</strong> {comboInfo?.name}
                      </Typography>
                      <Typography variant="subtitle1">
                        <strong>Hanger Model :</strong> {comboInfo?.hangerModel}
                      </Typography>
                    </Box>
                  </Box>
                  <Typography variant="h6" gutterBottom sx={{ mt: 3 }}>
                    Contenu du pack :
                  </Typography>
                  {comboProducts.flatMap((item, itemIndex) =>
                    item.serialNumbers.map((serialNumber, serialIndex) => (
                      <Box
                        key={`${itemIndex}-${serialIndex}`}
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          gap: 2,
                          mt: 1,
                        }}
                      >
                        <img
                          src={item.productId?.imageUrl || '/default-product-image.png'}
                          alt={item.productId?.model}
                          style={{ width: '40px', height: 'auto', borderRadius: 4 }}
                        />
                        <Box sx={{ flex: 1 }}>
                          <Typography variant="body2">
                            {item.productId?.model} (Hanger Model: {item.productId?.productNumber})
                          </Typography>
                          <Typography variant="caption">
                            Numéro de série: <strong>{serialNumber}</strong>
                          </Typography>
                        </Box>
                      </Box>
                    ))
                  )}
                </>
              )}
              <Box sx={{ mt: 3 }}>
                <Typography variant="subtitle1" gutterBottom>
                  <strong>Date d'achat :</strong>{' '}
                  {new Date(purchaseDate).toLocaleDateString('fr-FR')}
                </Typography>
                <Typography variant="subtitle1" gutterBottom>
                  <strong>Nom du revendeur :</strong> {retailerName}
                </Typography>
              </Box>
            </Box>

            {/* Boutons de navigation */}
            <Box sx={{ mt: 5, display: 'flex', justifyContent: 'space-between' }}>
              <Button
                variant="contained"
                onClick={handlePrevious}
                sx={{ width: '150px', py: 1.5, borderRadius: '12px', fontWeight: 'bold' }}
              >
                Précédent
              </Button>
              <Button
                variant="contained"
                onClick={
                  registrationType === 'single'
                    ? handleSingleRegistration
                    : handleComboRegistration
                }
                sx={{ width: '200px', py: 1.5, borderRadius: '12px', fontWeight: 'bold' }}
              >
                Enregistrer {registrationType === 'single' ? 'le produit' : 'le pack'}
              </Button>
            </Box>
          </Paper>
        </Box>
      )}
      
      {/* Étape 4 - Confirmation */}
      {step === 4 && (
        <Box sx={{ mt: 5, textAlign: 'center' }}>
          <Helmet>
            <title>Confirmation d'enregistrement - Hanger</title>
          </Helmet>

          <Typography variant={isMobile ? 'h5' : 'h4'} color="success.main" gutterBottom>
            {isCombo
              ? 'Félicitations ! Vos garanties pour le pack ont été enregistrées avec succès'
              : 'Félicitations ! Votre garantie a été enregistrée avec succès'}
          </Typography>

          <Box sx={{ mt: 3 }}>
            <Typography variant="h6" sx={{ mb: 1 }}>
              Merci de faire confiance à Hanger-tools !
            </Typography>
            <Typography variant="body1" color="text.secondary" sx={{ mb: 3 }}>
              {isCombo
                ? 'Nous sommes heureux de vous compter parmi nos clients et nous vous garantissons un service de qualité pour toute la durée de vie de vos produits inclus dans le pack.'
                : 'Nous sommes heureux de vous compter parmi nos clients et nous vous garantissons un service de qualité pour toute la durée de vie de votre produit.'}
            </Typography>
          </Box>

          {/* Liste des garanties enregistrées */}
          {isCombo ? (
            <Box sx={{ mt: 5 }}>
              <Typography variant="h6" gutterBottom>
                Produits enregistrés dans le pack :
              </Typography>
              {comboProducts.flatMap((item, itemIndex) =>
                item.serialNumbers.map((serialNumber, serialIndex) => (
                  <Box
                    key={`${itemIndex}-${serialIndex}`}
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      gap: 2,
                      mt: 2,
                    }}
                  >
                    <img
                      src={item.productId?.imageUrl || '/default-product-image.png'}
                      alt={item.productId?.model || 'Produit'}
                      style={{ width: '50px', height: 'auto', borderRadius: 4 }}
                    />
                    <Box>
                      <Typography variant="body1">{item.productId?.model}</Typography>
                      <Typography variant="caption">
                        (Hanger Model: {item.productId?.productNumber})
                      </Typography>
                      <Typography variant="caption">
                        (Numéro de série: {serialNumber})
                      </Typography>
                    </Box>
                  </Box>
                ))
              )}
            </Box>
          ) : (
            <Box sx={{ mt: 5 }}>
              <Typography variant="h6" gutterBottom>
                Détails de votre garantie :
              </Typography>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  gap: 2,
                  mt: 2,
                }}
              >
                <img
                  src={singleProduct?.productId?.imageUrl || '/default-product-image.png'}
                  alt={singleProduct?.productId?.model || 'Produit'}
                  style={{ width: '250px', height: 'auto', borderRadius: 4 }}
                />
                <Box>
                  <Typography variant="body1">{singleProduct?.productId?.model}</Typography>
                  <Typography variant="caption">
                    (Hanger Model: {singleProduct?.productId?.productNumber})
                  </Typography>
                  <Typography variant="caption">
                    (Numéro de série: {singleProduct?.serialNumber})
                  </Typography>
                </Box>
              </Box>
            </Box>
          )}

          <Box sx={{ mt: 5, mb: 5 }}>
            <Button
              variant="contained"
              color="primary"
              size="large"
              onClick={() => navigate('/user-warranties')}
              sx={{
                fontWeight: 'bold',
                px: 5,
                py: 1.5,
                borderRadius: '12px',
                textTransform: 'none',
              }}
            >
              Voir mes garanties
            </Button>
          </Box>

          <Box
            sx={{
              backgroundColor: theme.palette.background.paper,
              p: 4,
              borderRadius: 2,
              mt: 5,
              textAlign: 'left',
              boxShadow: 1,
            }}
          >
            <Typography variant="h6" gutterBottom>
              Pourquoi choisir Hanger-tools ?
            </Typography>
            <Typography variant="body1" color="text.primary" sx={{ lineHeight: 1.6 }}>
              <span style={{ fontWeight: 'bold', color: theme.palette.primary.main }}>
                Hanger-tools
              </span>
              , c'est plus de <strong>20 ans d'engagement</strong> pour offrir des produits de haute
              qualité, avec un service après-vente qui fait la différence.{' '}
              <span style={{ fontStyle: 'italic' }}>Votre satisfaction est notre priorité</span> –
              nous sommes là pour vous accompagner dans l'utilisation de vos outils au quotidien.
            </Typography>
            <Typography
              variant="body2"
              color="text.secondary"
              sx={{ mt: 2, fontStyle: 'italic' }}
            >
              Rejoignez des milliers de clients satisfaits qui nous font déjà confiance et bénéficiez
              d'un service personnalisé, adapté à vos besoins.
            </Typography>
          </Box>
        </Box>
      )}
    </Container>
  );
}

export default WarrantyRegistrationSteps;
