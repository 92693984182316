import React from 'react';
import { Container, Box, Typography, Link } from '@mui/material';

function Terms() {
  return (
    <Container maxWidth="lg">
      <Box sx={{ mt: 5 }}>
        <Typography variant="h4" component="h1" sx={{ fontWeight: 700, mb: 1 }}>
          Conditions Générales d'Utilisation
        </Typography>
        <Typography variant="body1" paragraph>
          Bienvenue sur le site de Hanger. En utilisant notre site web, vous acceptez les conditions suivantes. Veuillez les lire attentivement.
        </Typography>

        <Typography variant="h5" component="h2" gutterBottom>
          1. Acceptation des Conditions
        </Typography>
        <Typography variant="body1" paragraph>
          En accédant à notre site, vous acceptez d'être lié par les présentes conditions d'utilisation ainsi que par toutes les lois et réglementations applicables. Si vous n'acceptez pas ces conditions, veuillez ne pas utiliser notre site.
        </Typography>

        <Typography variant="h5" component="h2" gutterBottom>
          2. Objet du Site
        </Typography>
        <Typography variant="body1" paragraph>
          Notre site permet aux utilisateurs d'enregistrer les garanties de leurs produits achetés, de consulter les informations relatives à ces garanties et de bénéficier de services associés.
        </Typography>

        <Typography variant="h5" component="h2" gutterBottom>
          3. Inscription et Compte Utilisateur
        </Typography>
        <Typography variant="body1" paragraph>
          Pour accéder à certains services, vous devez créer un compte en fournissant des informations exactes et à jour. Vous êtes responsable de la confidentialité de vos identifiants et de toutes les activités effectuées depuis votre compte.
        </Typography>

        <Typography variant="h5" component="h2" gutterBottom>
          4. Enregistrement des Produits et Garanties
        </Typography>
        <Typography variant="body1" paragraph>
          En enregistrant vos produits sur notre site, vous certifiez que les informations fournies sont exactes et que vous êtes le propriétaire légitime des produits. L'enregistrement permet de faciliter la gestion de vos garanties, mais n'affecte pas les conditions de garantie fournies par le fabricant ou le vendeur.
        </Typography>

        <Typography variant="h5" component="h2" gutterBottom>
          5. Utilisation du Contenu
        </Typography>
        <Typography variant="body1" paragraph>
          Tout le contenu présent sur ce site, y compris les textes, graphiques, logos, images et logiciels, est la propriété de Hanger ou de ses fournisseurs de contenu. Vous ne pouvez pas copier, modifier, distribuer ou utiliser ce contenu sans autorisation préalable.
        </Typography>

        <Typography variant="h5" component="h2" gutterBottom>
          6. Responsabilités de l'Utilisateur
        </Typography>
        <Typography variant="body1" paragraph>
          Vous vous engagez à utiliser le site conformément à la loi et aux présentes conditions. Il est interdit de :
        </Typography>
        <Typography variant="body1" component="ul" paragraph>
          <li>Fournir des informations fausses ou trompeuses lors de l'enregistrement ou de l'utilisation du site.</li>
          <li>Usurper l'identité d'une autre personne ou entité.</li>
          <li>Accéder ou tenter d'accéder à des zones non autorisées du site.</li>
          <li>Utiliser le site de manière à endommager, désactiver, surcharger ou altérer son fonctionnement.</li>
        </Typography>

        <Typography variant="h5" component="h2" gutterBottom>
          7. Protection des Données Personnelles
        </Typography>
        <Typography variant="body1" paragraph>
          Nous nous engageons à protéger vos données personnelles conformément à notre <Link href="/privacy">Politique de Confidentialité</Link>. En utilisant notre site, vous consentez à la collecte et à l'utilisation de vos données conformément à cette politique.
        </Typography>

        <Typography variant="h5" component="h2" gutterBottom>
          8. Liens vers des Sites Tiers
        </Typography>
        <Typography variant="body1" paragraph>
          Notre site peut contenir des liens vers des sites tiers. Nous ne contrôlons pas ces sites et ne sommes pas responsables de leur contenu ou de leurs pratiques. L'accès à ces sites se fait à vos propres risques.
        </Typography>

        <Typography variant="h5" component="h2" gutterBottom>
          9. Modification des Conditions
        </Typography>
        <Typography variant="body1" paragraph>
          Hanger se réserve le droit de modifier ces conditions à tout moment. Les modifications seront publiées sur cette page et entreront en vigueur immédiatement après leur publication. Il est de votre responsabilité de consulter régulièrement cette page pour être informé des éventuelles modifications.
        </Typography>

        <Typography variant="h5" component="h2" gutterBottom>
          10. Limitation de Responsabilité
        </Typography>
        <Typography variant="body1" paragraph>
          Hanger ne peut être tenu responsable des dommages directs ou indirects résultant de l'utilisation de ce site ou de l'impossibilité d'y accéder. Nous ne garantissons pas que le site sera exempt d'erreurs ou d'interruptions.
        </Typography>

        <Typography variant="h5" component="h2" gutterBottom>
          11. Droit Applicable et Juridiction
        </Typography>
        <Typography variant="body1" paragraph>
          Les présentes conditions sont régies par le droit français. En cas de litige, et après échec de toute tentative de résolution amiable, les tribunaux français seront seuls compétents.
        </Typography>

        <Typography variant="h5" component="h2" gutterBottom>
          12. Contact
        </Typography>
        <Typography variant="body1" paragraph>
          Pour toute question concernant ces conditions, veuillez nous contacter à l'adresse suivante : <Link href="mailto:contact@hangertools.com">contact@hangertools.com</Link>.
        </Typography>
      </Box>
    </Container>
  );
}

export default Terms;
