import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import {
  Container,
  Typography,
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Snackbar,
  Alert,
  TextField,
  IconButton,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
  InputAdornment,
  TablePagination,
} from '@mui/material';
import { UploadFile, Search } from '@mui/icons-material';
import * as XLSX from 'xlsx';
import moment from 'moment';

const AdminSerialNumberManagement = () => {
  const [serialNumbers, setSerialNumbers] = useState([]);
  const [filteredSerialNumbers, setFilteredSerialNumbers] = useState([]);
  const [previewData, setPreviewData] = useState([]); // Données pour la prévisualisation
  const [searchTerm, setSearchTerm] = useState('');
  const [statusFilter, setStatusFilter] = useState('');
  const [categoryFilter, setCategoryFilter] = useState('');
  const [categories, setCategories] = useState([]);
  const [openPreviewDialog, setOpenPreviewDialog] = useState(false);
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    severity: 'success',
  });
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  useEffect(() => {
    const fetchSerialNumbers = async () => {
      try {
        const token = localStorage.getItem('adminToken');
        const response = await fetch('http://localhost:5000/api/admin/serial-numbers', {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        });

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();
        const formattedData = data.map((serial) => ({
          ...serial,
          dateOfManufacture: moment(serial.dateOfManufacture).format('DD/MM/YYYY'),
          createdAt: moment(serial.createdAt).format('DD/MM/YYYY'),
        }));

        setSerialNumbers(formattedData);
        setFilteredSerialNumbers(formattedData);
        setCategories([...new Set(formattedData.map(serial => serial.product?.category).filter(Boolean))]);
      } catch (error) {
        console.error('Erreur lors de la récupération des numéros de série:', error);
        setSnackbar({ open: true, message: 'Erreur lors de la récupération des numéros de série', severity: 'error' });
      }
    };

    fetchSerialNumbers();
  }, []);

  const handleFileChange = async (e) => {
    const file = e.target.files[0];
    if (!file) return;

    const reader = new FileReader();

    reader.onload = async (event) => {
      const data = event.target.result;
      const workbook = XLSX.read(data, { type: 'binary' });
      const sheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[sheetName];
      const json = XLSX.utils.sheet_to_json(worksheet, { raw: false, defval: '' });

      const formattedData = json.map((item) => ({
        serialNumber: item.serialNumber?.trim(),
        productId: item.productId?.trim(),
        dateOfManufacture: moment(item.dateOfManufacture, 'DD/MM/YYYY').toISOString(),
      })).filter(item => item.serialNumber && item.productId && item.dateOfManufacture);

      setSerialNumbers(formattedData);
      setPreviewData(formattedData.slice(0, 5)); // Prévisualisation des 5 premiers enregistrements
      setOpenPreviewDialog(true);
    };

    reader.readAsBinaryString(file);
  };

  const handleConfirmImport = async () => {
    if (serialNumbers.length === 0) {
      setSnackbar({ open: true, message: 'Aucun numéro de série à importer', severity: 'warning' });
      return;
    }
  
    try {
      const token = localStorage.getItem('adminToken');
      if (!token) {
        throw new Error("Token d'authentification manquant");
      }
  
      console.log('Envoi des données pour importation:', serialNumbers);
  
      const response = await fetch('http://localhost:5000/api/admin/serial-numbers/import', {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ serialNumbers }),
      });
  
      console.log('Réponse du serveur:', response);
  
      if (!response.ok) {
        const errorMessage = await response.text();
        throw new Error(errorMessage || `Erreur HTTP : ${response.status}`);
      }
  
      const result = await response.json();
      console.log('Résultat de l’importation:', result);
  
      setSnackbar({ open: true, message: 'Numéros de série importés avec succès', severity: 'success' });
      setOpenPreviewDialog(false);
      setFilteredSerialNumbers(serialNumbers); 
    } catch (error) {
      console.error('Erreur lors de l’importation des numéros de série:', error);
      setSnackbar({ open: true, message: error.message || 'Erreur lors de l’importation des numéros de série', severity: 'error' });
    }
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
    applyFilters(e.target.value, statusFilter, categoryFilter);
  };

  const handleStatusFilterChange = (e) => {
    setStatusFilter(e.target.value);
    applyFilters(searchTerm, e.target.value, categoryFilter);
  };

  const handleCategoryFilterChange = (e) => {
    setCategoryFilter(e.target.value);
    applyFilters(searchTerm, statusFilter, e.target.value);
  };

  const applyFilters = (search, status, category) => {
    let filtered = serialNumbers;

    if (search) {
      filtered = filtered.filter((serial) =>
        Object.values(serial).some((val) =>
          String(val).toLowerCase().includes(search.toLowerCase())
        ) || (serial.product?.productNumber && serial.product.productNumber.toLowerCase().includes(search.toLowerCase()))
      );
    }

    if (status) {
      filtered = filtered.filter((serial) => serial.status === status);
    }

    if (category) {
      filtered = filtered.filter((serial) => serial.product?.category === category);
    }

    setFilteredSerialNumbers(filtered);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <Box sx={{ display: 'flex' }}>
      <Helmet>
        <title>Gestion des Numéros de Série - Admin</title>
        <meta name="description" content="Page de gestion des numéros de série pour l'administrateur. Importer, filtrer et visualiser les détails des numéros de série." />
      </Helmet>
      <Container>
        <Box my={4}>
          <Typography variant="h4" component="h1" gutterBottom>
            Gestion des Numéros de Série
          </Typography>
          <Button
            variant="contained"
            component="label"
            color="primary"
            startIcon={<UploadFile />}
            sx={{ mb: 2 }}
          >
            Importer un fichier
            <input type="file" hidden accept=".csv, .xlsx" onChange={handleFileChange} />
          </Button>
          <Box sx={{ display: 'flex', gap: 2, mb: 2 }}>
            <TextField
              label="Rechercher"
              variant="outlined"
              value={searchTerm}
              onChange={handleSearchChange}
              InputProps={{
                startAdornment: <InputAdornment position="start"><Search /></InputAdornment>,
              }}
              fullWidth
            />
            <FormControl variant="outlined" sx={{ minWidth: 200 }}>
              <InputLabel>Statut</InputLabel>
              <Select
                value={statusFilter}
                onChange={handleStatusFilterChange}
                label="Statut"
              >
                <MenuItem value="">
                  <em>Tout</em>
                </MenuItem>
                <MenuItem value="available">Available</MenuItem>
                <MenuItem value="assigned">Assigned</MenuItem>
              </Select>
            </FormControl>
            <FormControl variant="outlined" sx={{ minWidth: 200 }}>
              <InputLabel>Catégorie</InputLabel>
              <Select
                value={categoryFilter}
                onChange={handleCategoryFilterChange}
                label="Catégorie"
              >
                <MenuItem value="">
                  <em>Tout</em>
                </MenuItem>
                {categories.map((category, index) => (
                  <MenuItem key={index} value={category}>
                    {category}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Numéro de Série</TableCell>
                  <TableCell>Produit Réf</TableCell>
                  <TableCell>Nom du Produit</TableCell>
                  <TableCell>Catégorie</TableCell>
                  <TableCell>Date de Fabrication</TableCell>
                  <TableCell>Statut</TableCell>
                  <TableCell>Date de Création</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredSerialNumbers.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((serial, index) => (
                  <TableRow key={index}>
                    <TableCell>{serial.serialNumber}</TableCell>
                    <TableCell>{serial.product?.productNumber || 'N/A'}</TableCell>
                    <TableCell>{serial.product?.model || 'N/A'}</TableCell>
                    <TableCell>{serial.product?.category || 'N/A'}</TableCell>
                    <TableCell>{serial.dateOfManufacture}</TableCell>
                    <TableCell>{serial.status || 'N/A'}</TableCell>
                    <TableCell>{serial.createdAt}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={filteredSerialNumbers.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Box>
        <Dialog open={openPreviewDialog} onClose={() => setOpenPreviewDialog(false)} maxWidth="md" fullWidth>
          <DialogTitle>Confirmer l'importation</DialogTitle>
          <DialogContent>
            <Typography>Voulez-vous vraiment importer ces numéros de série ?</Typography>
            <TableContainer component={Paper} sx={{ maxHeight: 300, mt: 2 }}>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell>Numéro de Série</TableCell>
                    <TableCell>Produit Réf</TableCell>
                    <TableCell>Date de Fabrication</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {previewData.map((serial, index) => (
                    <TableRow key={index}>
                      <TableCell>{serial.serialNumber}</TableCell>
                      <TableCell>{serial.productId}</TableCell>
                      <TableCell>{moment(serial.dateOfManufacture).format('DD/MM/YYYY')}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <Typography variant="body2" color="textSecondary" sx={{ mt: 2 }}>
              Affichage des 5 premiers numéros de série pour prévisualisation.
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setOpenPreviewDialog(false)} color="secondary">
              Annuler
            </Button>
            <Button
              onClick={handleConfirmImport}
              color="primary"
              sx={{ color: '#ffffff', backgroundColor: '#1976d2', '&:hover': { backgroundColor: '#1565c0' } }}
            >
              Confirmer l'importation
            </Button>
          </DialogActions>
        </Dialog>
        <Snackbar
          open={snackbar.open}
          autoHideDuration={6000}
          onClose={() => setSnackbar({ ...snackbar, open: false })}
        >
          <Alert
            onClose={() => setSnackbar({ ...snackbar, open: false })}
            severity={snackbar.severity}
            sx={{ width: '100%' }}
          >
            {snackbar.message}
          </Alert>
        </Snackbar>
      </Container>
    </Box>
  );
};

export default AdminSerialNumberManagement;
