import { configureStore } from '@reduxjs/toolkit';
import authReducer from './authSlice';

const store = configureStore({
  reducer: {
    auth: authReducer,
  },
});

store.subscribe(() => {
  console.log('Redux store state changed:', store.getState());
});

console.log('Redux store initialized:', store.getState());

export default store;
