// src/components/admin/AdminLayout.js
import React from 'react';
import { Box } from '@mui/material';
import Sidebar from '../Sidebar';

const AdminLayout = ({ children }) => {
  return (
    <Box sx={{ display: 'flex' }}>
      <Sidebar />
      <Box sx={{ flexGrow: 1, p: 3 }}>
        {children}
      </Box>
    </Box>
  );
};

export default AdminLayout;
